import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/HeaderUser';
import Footer from '../Helper/Footer';
import userImg1 from '../../assets/images/user-img1.jpg';
import { BASEAPIURL, BASEURL } from '../../config';

const Token = localStorage.getItem('token');
const jwttoken = localStorage.getItem('jwttoken');
const LSName = localStorage.getItem('fname');
const LSEmail = localStorage.getItem('email');

function Contactdetail() {
    // const [user, setUser] = useState([]);
    const [drawer, drawerAction] = useToggle(false);
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        if (Token && Token !== 0 && Token !== '0') {
            console.log(Token);
        } else if (jwttoken && jwttoken !== 0 && jwttoken !== '0') {
            console.log(jwttoken);
        } else {
            window.location.href = `${BASEURL}login?action=login&message=Please Login to Continue`;
        }
        const fetchUser = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/user/getProfile`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        token: Token,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    // setUser(resJson.user);
                    // setOldPassword(resJson.oldpassword);
                    // setNewPassword(resJson.newpassword);
                    // setConfirmPassword(resJson.confirmpassword);
                    console.log(resJson.data);
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
                } else {
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-danger">${resJson.message}</div>`));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchUser();
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        console.log(Token, address3, address1, address2, pincode, city, state);
        const data = { token: Token, address3, address1, address2, pincode, city, state };
        fetch(`https://services.mymoneypanda.in/api/usercontact/createContact`, {
            method: 'POST',
            headers: {
                // Accept: 'application/json',
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log(result);
            result.json().then((resp) => {
                setMessage(Parser(`<div class="alert alert-success">${resp.message}</div>`));
                console.log(resp);
            });
        });
    }

    // function handleCancel() {
    //     setTimeout(() => {
    //         window.location.href = `${BASEURL}user-panel`;
    //     }, 100);
    //     console.log(BASEURL);
    // }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />

            <section className="admin-user-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile-block">
                                <div className="leftBox">
                                    <img src={userImg1} alt="" />
                                    <h5>{LSName}</h5>
                                    {/* <h5>{user.fname}</h5> */}
                                    <a href="#">
                                        <i className="far fa-envelope" />
                                        {LSEmail}
                                    </a>
                                    <hr />
                                    <div className="navBox">
                                        <NavLink exact to="/profile">
                                            <i className="far fa-user" />
                                            Basic Details
                                        </NavLink>
                                        {/* <NavLink exact to="/ContactDetail">
                                            <i className="far fa-address-book" />
                                            &nbsp;Contact Details
                                        </NavLink>
                                        <NavLink exact to="/BankDetail">
                                            <i className="far fa-landmark"></i>
                                            Bank Details
                                        </NavLink>
                                        <NavLink exact to="/nominee">
                                            <i className="far fa-user-plus" />
                                            Nominee Details
                                        </NavLink> */}
                                        <NavLink exact to="/change-password">
                                            <i className="far fa-lock" />
                                            Change Password
                                        </NavLink>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                localStorage.removeItem('jwttoken');
                                                window.location.href = BASEURL;
                                            }}
                                        >
                                            <div className="iconBox">
                                                <i className="fal fa-sign-out" />
                                            </div>
                                            <div>Logout</div>
                                        </NavLink>
                                    </div>
                                </div>
                                <hr className="d-lg-none d-block mt-50 mb-50" />
                                <div className="rightBox">
                                    <h6>Contact Details</h6>
                                    <form method="POST" onSubmit={handleSubmit}>
                                        <div className="form-area mb-20">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-box">
                                                        {/* <input
                                                            type="text"
                                                            name="oldPassword"
                                                            // value={user.password}
                                                            placeholder="Address Line 1"
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        /> */}
                                                        <textarea
                                                            type="text"
                                                            name="Address1"
                                                            // value={user.confirmpassword}
                                                            placeholder="Address Line 1"
                                                            onChange={(e) => {
                                                                setAddress1(e.target.value);
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '62px',
                                                                margin: '20px',
                                                                padding: '8px',
                                                            }}
                                                        ></textarea>
                                                        <label>Address Line 1</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        {/* <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Address Line 2"
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        /> */}
                                                        <textarea
                                                            type="text"
                                                            name="Address2"
                                                            // value={user.confirmpassword}
                                                            placeholder="Address Line 2"
                                                            onChange={(e) => {
                                                                setAddress2(e.target.value);
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '62px',
                                                                margin: '20px',
                                                                padding: '8px',
                                                            }}
                                                        ></textarea>
                                                        <label>Address Line 2</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        {/* <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Address Line 3"
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                        /> */}
                                                        <textarea
                                                            type="text"
                                                            name="Address3"
                                                            // value={user.confirmpassword}
                                                            placeholder="Address Line 3"
                                                            onChange={(e) => {
                                                                setAddress3(e.target.value);
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '62px',
                                                                margin: '20px',
                                                                padding: '8px',
                                                            }}
                                                        ></textarea>
                                                        <label>Address Line 3</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="number"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Pincode"
                                                            onChange={(e) => {
                                                                setPincode(e.target.value);
                                                            }}
                                                            style={{
                                                                margin: '20px',
                                                            }}
                                                        />
                                                        <label>Pincode</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="City"
                                                            onChange={(e) => {
                                                                setCity(e.target.value);
                                                            }}
                                                            style={{
                                                                margin: '20px',
                                                            }}
                                                        />
                                                        <label>City</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="State"
                                                            onChange={(e) => {
                                                                setState(e.target.value);
                                                            }}
                                                            style={{
                                                                margin: '20px',
                                                            }}
                                                        />
                                                        <label>State</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ clear: 'both' }}>
                                                <div style={styleMSG}>{message}</div>
                                            </div>
                                            <div className="col-lg-12 mt-20 text-lg-left text-center">
                                                <input type="submit" name="submit" value="Save" />
                                                <input
                                                    type="submit"
                                                    name="submit"
                                                    value="cancel"
                                                    className="cancel"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
}

export default Contactdetail;
