export const ContactEmailID = 'enquiry@docstainstitute.com';
export const ContactMobile = '+971-543-229-664';
export const ContactWhatsapp = '+971543229664';
export const GoogleAnylyticsID = 'G-TMKK4MSLHV';
const AllMonthsName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const BASEAPIURL = 'https://services.mymoneypanda.in/api';
// export const BASEAPIURL = 'http://localhost:4000/api';
export const IMGBASEAPIURL = 'https://mymoneypanda.in/images/'; // for react frontend files only
export const UPLOADNODEURL = 'https://services.mymoneypanda.in/uploads/'; // for react frontend files only
export const BASEURL_LOCAL = 'http://localhost:3000/';
export const BASEURL_LIVE = 'https://mymoneypanda.in/';
export const BASEURL = BASEURL_LIVE;
export function setCookie(name, value, days) {
    let expires = '';
    let temp = '';
    const date = new Date();
    if (days) {
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        temp = date.toUTCString();
        expires = `${temp}`;
    }
    // document.cookie = name + '=' + (value || '') + expires + '; path=/';
    console.log(`${name}=${value || ''}; expires=${expires}; path=/;`);
    document.cookie = `${name}=${value || ''}; expires=${expires}; path=/;`;
}
export function getCookie(name) {
    const nameEQ = `${name}=`;
    let i = 0;
    let c = 0;
    const ca = document.cookie.split(';');
    for (i = 0; i < ca.length; i = +1) {
        c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function RemoveCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
export function logoutaLL() {
    localStorage.removeItem('username');
    localStorage.removeItem('usertype');
    localStorage.removeItem('token');
    RemoveCookie('rytoken');
    window.location.href = BASEURL;
}
export function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()} ${AllMonthsName[tmp.getMonth()]}, ${tmp.getUTCFullYear()}`;
}
function gettimeinampm(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    if (hours === 0) {
        hours = 12;
    }
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    // const strTime = hours + ':' + minutes + ' ' + ampm;
    return `${hours}:${minutes} ${ampm}`;
}
export function showfulldatetime(datetime) {
    const tmp = new Date(datetime);
    // alert(tmp);
    return `${tmp.getDate()}/${Number(tmp.getMonth()) + 1}/${tmp.getUTCFullYear()} ${gettimeinampm(
        tmp
    )}`;
}
export function CleanUrlWork(string) {
    return string.toLowerCase().replace(/[^0-9A-Z]+/gi, '_');
}
export function ShowValueInFormat(value) {
    return value.toLocaleString();
}
// https://stackoverflow.com/questions/5294074/pmt-function-in-javascript
export function GetPMT(ir, np, pv, fv) {
    let pmt = 0;
    let temp = 0;
    let pvif = 0;
    let typeHere = 0;

    typeHere = 0;

    if (ir === 0) return -(pv + fv) / np;

    pvif = (1 + ir) ** np;
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (typeHere === 1) {
        temp = 1 + ir;
        pmt /= temp;
    }
    pmt = pmt.toFixed(0);
    // pmt - number
    return pmt * -1;
}
