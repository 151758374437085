import React, { useState } from "react";
import minus from "../../assets/images/mutualfundimg/Icon wrap.svg";
import plus from "../../assets/images/mutualfundimg/Icon wrap (1).svg";
import './css/sixherosection.css'

function FAQComponent({questions}){
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (id) => {
        setOpenIndex(openIndex === id ? null : id);
    };

    return <>
        <div className="sihs-main-calculator">
         <h2 className="sihs-title-calculator">Frequently asked questions</h2>
         <div className="sihs-container">
            {questions.map((item) => (
               <div key={item.id} className="sihs-question-calculator" style={{paddingBottom : openIndex === item.id ? '2rem' : '1rem'}}>
                  <div className="sihs-question-header">
                     <p className="sihs-question-title">{item.question}</p>
                     <div onClick={() => handleClick(item.id)} className="sihs-question-icon">
                        <img src={openIndex === item.id ? minus : plus} alt={openIndex === item.id ? "minus" : "plus"}/>
                     </div>
                  </div>
                  {openIndex === item.id && (
                     <p className="sihs-answer">
                        {item.answer}
                     </p>
                  )}
               </div>
            ))}
         </div>
      </div>
    </>
}

export default FAQComponent;
