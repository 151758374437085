import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

let EMIsExpenses = 0;
let UtilitiesExpenses = 0;
let FamilyExpenses = 0;
let OtherExpenses = 0;
let Saving = 0;

function numberWithCommas(x) {
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let ReturnValue = '';
    if (x && x !== '' && x !== null) {
        ReturnValue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // ReturnValue = 'saf';
    } else {
        ReturnValue = '';
    }
    return ReturnValue;
}

const IncomeExpensenses = ({ expensesValue, rewiInvst }) => {
    const [InvestibleSurplus, setInvestibleSurplus] = useState('');
    const [ExpensesData, setExpensesData] = useState([['Actity Type', 'Total Activity']]);
    const [ExpensesOptions, setExpensesOptions] = useState({
        title: 'Report Summary',
        is3D: true,
        colors: ['#fb5d5d', '#70cc00', '#0f0f0f', '#cc0000', '#3b6c09'],
    });

    const [IncomeData, setIncomeData] = useState([['Type', 'Total']]);
    const [IncomeOptions, setIncomeOptions] = useState({
        title: 'Report Summary',
        is3D: true,
        colors: ['#fb5d5d', '#70cc00', '#0f0f0f', '#cc0000', '#3b6c09'],
    });

    function calculateExpensesSaving(expensesValue2, rewiInvst2) {
        setIncomeOptions({
            title: 'Report Summary',
            is3D: false,
            colors: ['#3D52ED', '#00EBB4'],
        });
        if (rewiInvst2.monthlyIncome) {
            setIncomeData([
                ['Type', 'Total'],
                [
                    `Monthly Income (₹${numberWithCommas(Number(rewiInvst2.monthlyIncome))})`,
                    Number(rewiInvst2.monthlyIncome),
                ],
                [
                    `Anual Income (₹${numberWithCommas(Number(rewiInvst2.monthlyIncome) * 12)})`,
                    Number(rewiInvst2.monthlyIncome) * 12,
                ],
            ]);
        } else {
            setIncomeData([
                ['Type', 'Total'],
                ['Monthly Income', 0],
                ['Anual Income', 0],
            ]);
        }

        Saving = 0;
        EMIsExpenses = 0;
        UtilitiesExpenses = 0;
        FamilyExpenses = 0;
        OtherExpenses = 0;
        // 1 EMIs 2 Utilities 3 Family 4 Other>
        expensesValue2.map((s) => {
            if (s.type && Number(s.type) === 1) {
                EMIsExpenses = Number(EMIsExpenses) + Number(s.amount);
            }
            if (s.type && Number(s.type) === 2) {
                UtilitiesExpenses = Number(UtilitiesExpenses) + Number(s.amount);
            }
            if (s.type && Number(s.type) === 3) {
                FamilyExpenses = Number(FamilyExpenses) + Number(s.amount);
            }
            if (s.type && Number(s.type) === 4) {
                OtherExpenses = Number(OtherExpenses) + Number(s.amount);
            }
            return '';
        });

        // Less than 15%
        // 15 - 30%
        // 30 - 45%
        // More than 45
        // I am not sure
        if (rewiInvst2.monthlyIncome && rewiInvst2.perMonthSave) {
            Saving =
                Number(rewiInvst2.monthlyIncome) -
                EMIsExpenses -
                UtilitiesExpenses -
                FamilyExpenses -
                OtherExpenses;
        }
        if (expensesValue2.length > 0) {
            setInvestibleSurplus(`₹${numberWithCommas(Saving)}`);
        } else {
            setInvestibleSurplus(`₹${numberWithCommas(Saving)}`);
        }

        setExpensesData([
            ['Expenses Type', 'Total Amount'],
            [`EMI (₹${numberWithCommas(EMIsExpenses)})`, EMIsExpenses],
            [`Utilities (₹${numberWithCommas(UtilitiesExpenses)})`, UtilitiesExpenses],
            [`Family (₹${numberWithCommas(FamilyExpenses)})`, FamilyExpenses],
            [`Other (₹${numberWithCommas(OtherExpenses)})`, OtherExpenses],
            [`Saving (₹${numberWithCommas(Saving)})`, Saving],
        ]);
        setExpensesOptions({
            title: '',
            is3D: true,
            colors: ['#007FFF', '#3D52ED', '#1000D3', '#ACFFD2', '#00EBB4'],
        });
    }

    useEffect(() => {
        calculateExpensesSaving(expensesValue, rewiInvst);
    }, [expensesValue, rewiInvst]);
    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="row mt-lg-5 mt-20" id="incomeData">
            <div className="col-lg-12">
                <div className="row">
                    <a
                        href="#"
                        className="d-none"
                        onClick={() => {
                            calculateExpensesSaving(expensesValue, rewiInvst);
                        }}
                    >
                        Hello jasohf
                    </a>

                    <div className="col-md-6">
                        <div
                            style={{
                                border: '1px solid #f5f5f5',
                                borderRadius: '10px',
                            }}
                        >
                            <h6
                                className="mt-2 ml-2 pb-2"
                                style={{
                                    borderBottom: '1px solid #f5f5f5',
                                }}
                            >
                                Income
                            </h6>
                            <Chart
                                className=""
                                chartType="PieChart"
                                data={IncomeData}
                                options={IncomeOptions}
                                width="100%"
                                height="280px"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div
                            style={{
                                border: '1px solid #f5f5f5',
                                borderRadius: '10px',
                            }}
                        >
                            <h6
                                className="mt-2 ml-2 pb-2"
                                style={{
                                    borderBottom: '1px solid #f5f5f5',
                                }}
                            >
                                Expenses & Saving
                            </h6>
                            <Chart
                                className=""
                                chartType="PieChart"
                                data={ExpensesData}
                                options={ExpensesOptions}
                                width="100%"
                                height="280px"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="mt-4"
                    style={{
                        background: '#3D52ED',
                        padding: '15px 20px',
                        borderRadius: '20px',
                    }}
                >
                    <h6 className="mb-2" style={{ color: '#fff', fontWeight: 500 }}>
                        Your Monthly Investible Surplus {InvestibleSurplus}
                    </h6>
                    <p style={{ color: '#fff', fontWeight: 200 }}>
                        Amount which you save and can invest on a monthly basis
                    </p>
                </div>
            </div>
        </div>
    );
};

export default IncomeExpensenses;
