import React, { useState } from 'react';
// import { useAuth0 } from "@auth0/auth0-react";
import Parser from 'html-react-parser';
// import { useHistory } from 'react-router-dom';
import loginIcon1 from '../../assets/images/login-icon1.png';
import loginIcon2 from '../../assets/images/login-icon2.png';
import { BASEURL, BASEAPIURL } from '../../config';

function SignUp() {
    // const history = useHistory();
    const [styleBTN, setstyleBTN] = useState({});
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState();
    const [user, SetUser] = useState({
        fname: '',
        mobile: '',
        email: '',
        password: '',
    });
    let name;
    let value;
    const HandleSignup = (e) => {
        console.log(e);
        name = e.target.name;
        value = e.target.value;
        SetUser({ ...user, [name]: value });
    };

    const userSignup = async (e) => {
        e.preventDefault();
        const { fname, mobile, email, password } = user;
        console.log(user);

        try {
            const res = await fetch(`${BASEAPIURL}/user/signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fname,
                    mobile,
                    email,
                    password,
                }),
            });
            setstyleMSG({ display: 'block' });
            setstyleBTN({ display: 'none' });

            const resJson = await res.json();
            if (resJson.status === 1) {
                setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
                // localStorage.setItem('token', resJson.token);
                // localStorage.setItem('email', resJson.email);
                // localStorage.setItem('password', resJson.password);
                setTimeout(() => {
                    // history.push('/login');
                    window.location.href = `${BASEURL}login`;
                }, 5000);
                console.log(BASEURL);
            } else {
                setMessage(Parser(`<div class+"alert alert-danger">${resJson.message}</div>`));
            }
            setstyleBTN({ display: '' });
        } catch (err) {
            setstyleBTN({ display: '' });
            setMessage(Parser(`<div class="alert alert-danger">${err}</div>`));
        }
    };
    return (
        <>
            <section className="login-area">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-5">
                            <div className="loginBox">
                                <h5>Sign Up</h5>
                                <form method="POST" onSubmit={userSignup}>
                                    <input
                                        type="text"
                                        required
                                        name="fname"
                                        value={user.fname}
                                        placeholder="Enter Your Full Name"
                                        onChange={HandleSignup}
                                    />
                                    <input
                                        type="number"
                                        required
                                        name="mobile"
                                        value={user.mobile}
                                        placeholder="Enter Your Mobile Number"
                                        onChange={HandleSignup}
                                    />
                                    <input
                                        type="email"
                                        required
                                        name="email"
                                        value={user.email}
                                        placeholder="Enter Your Email Id"
                                        onChange={HandleSignup}
                                    />
                                    <input
                                        type="password"
                                        required
                                        name="password"
                                        value={user.password}
                                        placeholder="Enter Password"
                                        onChange={HandleSignup}
                                    />
                                    <div className="bottomBox">
                                        <p className="text-left mb-2">
                                            <input type="checkbox" value={1} required /> I accept
                                            the&nbsp;
                                            <a href="#">Terms of Conditions</a>, and&nbsp;
                                            <a href="#">Privacy Policy</a> of <b>Wealthy Nivesh</b>
                                        </p>
                                    </div>
                                    <span>Have Referral Code</span>
                                    <div className="col-md-12" style={{ clear: 'both' }}>
                                        <div style={styleMSG}>{message}</div>
                                    </div>
                                    <div style={styleBTN} className="col-md-12 text-center">
                                        <input type="submit" name="submit" value="Sign Up" />
                                    </div>
                                    <div className="divider">
                                        <b>OR</b>
                                    </div>
                                    <div className="socialLogin">
                                        <a href="#" className="socialIcon">
                                            <img src={loginIcon1} alt="" />
                                        </a>
                                        <a href="#" className="socialIcon">
                                            <img src={loginIcon2} alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SignUp;
