import { NavLink } from 'react-router-dom';

function  Completion(){
    return ( <div>

    
    
<section
                className='review-area'
                style={{
                    background: '#fff' ,
                    minHeight: 'auto',
                    border:  '1px solid #f5f5f5' ,
                }}
            >
                <div className="container">
                    <div className="row justify-content-end">
                        <div
                            className='col-lg-12 mt-10'
                        >
                            <h5  >
                                <NavLink to="/">
                                    <i className="fas fa-chevron-left" />
                                </NavLink>
                                Let's build your profile!
                            </h5>
                            <div className="innerBox" style={{ overflowX: 'hidden' }}>
                                <div >
                                    <div >
                                       
                                    </div>

                                    {/* {page} */}
                                    <div
                                        className='text-center' 
                                    >
                                    <div>button</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        )
 
}
export default Completion