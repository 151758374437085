import React from 'react';
import { Link } from 'react-router-dom';

function PageFive({ AjaxClass, AjaxMsg, ReviewInvtID, isLogin }) {
    return (
        <main
            className="form-area"
            style={{
                border: '1px solid #f5f5f5',
                padding: '30px',
            }}
        >
            <div className="text-center">
                {/* <h5 className="">Our Team will Contact you Shortly...</h5> */}
                <div className="mt-20" asss={`mt-30 ${AjaxClass}`}>
                    {console.log(AjaxMsg)}
                    {ReviewInvtID === 0 ? (
                        <p>Thank You! for creating Investment Profile.</p>
                    ) : (
                        <p>Your Profile has been updated.</p>
                    )}
                </div>
            </div>
            <div className="mt-20 text-center">
                {/* #{isLogin}# */}
                <Link
                    to="/sign-up"
                    className="main-btn"
                    style={{ display: Number(isLogin) === 1 ? 'none' : '' }}
                >
                    Sign Up now to Track Your Profile.
                </Link>
                <Link
                    to="/user-panel"
                    className="main-btn"
                    style={{ display: Number(isLogin) === 1 ? '' : 'none' }}
                >
                    Back to My Investment Profile.
                </Link>
            </div>
        </main>
    );
}

export default PageFive;
