/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "@mui/material/Slider";
// import ExpandingBar from "./spendBar";
import style from "./css/calculator.module.css";
// import Chart from "./CalculationChart";
import CustomBarGraph from "./BarChart";

const CalculatorComponent = (props) => {
  const { type, onButtonClick, values, outputResult, graphData } = props;
  const [selectedType, setSelectedType] = useState(type);
  const {
    handleInvestmentEdit,
    handleInvestmentRange,
    handleDurationEdit,
    handleDurationRange,
    handleRoiRange,
    changeBack
  } = props;

  const handleButtonClick = (clickedType) => {
    onButtonClick(clickedType);
    setSelectedType(clickedType);
  };
  const formateValue = (value) => new Intl.NumberFormat('en-IN', {
    // style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  }).format(value);

  return (
    <div className={style.container}>
      <div className={style.container_left}>
        <div className={style.button_section}>
          <div className={style.toggleButton}>
            <button type="button"
              className={changeBack === "SIP" ? style.clicked : style.default}
              onClick={() => handleButtonClick("SIP")}>
              SIP
            </button>
            <button type="button"
              className={changeBack === "Lumpsum" ? style.clicked : style.default}
              onClick={() => handleButtonClick("Lumpsum")}>
              Lumpsum
            </button>
          </div>
          {/* <div className={style.selection_section}> */}
          <div className={style.value_container}>
            <div className={style.value_container2}>
              <div className={style.monthly}>{changeBack === "Lumpsum" ? "Total Investment" : "Monthly Investment"}</div>
              <div className={style.numbers}>
                <span className={style.currency_symbol}>₹</span>
                <input
                  type="number"
                  value={values.investment || 500}
                  onChange={handleInvestmentEdit}
                  inputMode="numeric"
                />
              </div>
            </div>
            <div className={style.range}>
              <div className={style.slider_component}>
                <Slider
                  value={values.investment || 500}
                  step={500}
                  min={values.investmentRange.min}
                  max={values.investmentRange.max}
                  onChange={handleInvestmentRange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => value}
                  sx={{
                    color: '#00EBB4',
                    height: '10px',
                    '& .MuiSlider-thumb': {
                      borderRadius: '100px',
                      color: "white",
                    },
                    '& .MuiSlider-.Mui-completed': {
                      color: "pink",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.value_container}>
            <div className={style.value_container2}>
              <div className={style.monthly}>Investment Duration</div>
              <div className={style.duration}>
                <input
                  type="text"
                  value={values.investmentDuration}
                  onChange={handleDurationEdit}
                  inputMode="numeric"
                />
                <span className={style.year_symbol}>Year</span>
              </div>
            </div>
            <div className={style.range}>
              <div className={style.slider_component}>
                <Slider
                  value={values.investmentDuration || 0}
                  min={values.durationRange.min}
                  max={values.durationRange.max}
                  onChange={handleDurationRange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => value}
                  sx={{
                    color: '#00EBB4',
                    height: '10px',
                    '& .MuiSlider-thumb': {
                      borderRadius: '100px',
                      color: "white",
                    },
                    '& .MuiSlider-.Mui-completed': {
                      color: "pink",
                    },
                  }}
                />
              </div>
              <div className={style.range_indicator}>
                <p>2 Year</p>
                <p>30 year</p>
              </div>
            </div>
          </div>
          <div className={style.value_container}>
            <div className={style.value_container2}>
              <div className={style.monthly}>ROI</div>
            </div>
            <div className={style.range}>
              <div className={style.slider_component}>
                <Slider
                  value={values.roi || 3}
                  min={values.roiRange.min}
                  max={values.roiRange.max}
                  onChange={handleRoiRange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value} %`}
                  sx={{
                    color: '#00EBB4',
                    height: '10px',
                    '& .MuiSlider-thumb': {
                      borderRadius: '100px',
                      color: "white",
                    },
                    '& .MuiSlider-.Mui-completed': {
                      color: "pink",
                    },
                  }}
                />
              </div>
              <div className={style.range_indicator}>
                <p>{`${values.roiRange.min}%`}</p>
                <p>{`${values.roiRange.max}%`}</p>
              </div>
            </div>
          </div>
          </div>
        {/* </div> */}
        <div className={style.start_sip}>
        <Link to={changeBack==="Lumpsum"?"":"/invest.mymoneypanda.in"} target="_black">
          {changeBack === "Lumpsum" ? "Start Investment" : "Start SIP"}
        </Link>
        </div>
      </div>
      <div className={style.container_right}>
        <div className={style.container_right_big}>
          {/* <Chart values={outputResult} /> */}
          <CustomBarGraph graphData={graphData} />
        </div>
        <div className={style.container_right_small}>
          <div className={style.invested_value}>
            <p>Invested Amount </p>
            <p>₹ {formateValue(outputResult.investedAmount)}</p>
          </div>
          <div className={style.estimated_returns}>
            <p>Estimate Returns</p>
            <p>₹ {formateValue(outputResult.estimateReturn)}</p>
          </div>
          <div className={style.total_value}>
            <p>Total Value</p>
            <p>₹ {formateValue(outputResult.totalValue)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorComponent;
