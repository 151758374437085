import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import Parser from 'html-react-parser';
// import blog1 from '../../assets/images/blog-img1.jpg';
// import blog2 from '../../assets/images/blog-img2.jpg';
// import blog3 from '../../assets/images/blog-img3.jpg';
import moment from 'moment';
import { BASEAPIURL, UPLOADNODEURL } from '../../config';

function BlogBlock({ className }) {
    const [blogPage, setBlogPage] = useState([]);

    const fetchblogPage = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/blog/getAll`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    RY: 'SJAIN',
                    recordsPerPage: 100,
                    sortby: 'a2z',
                }),
            });

            const resJson = await res.json();
            if (res.status === 200) {
                setBlogPage(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        fetchblogPage();
    }, []);

    return (
        <>  
            <section className={`blogpage-section ${className || ''}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">My Money Panda Blog</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="search-block">
                                <div className="input-box">
                                    <input type="text" placeholder="Search" />
                                    <button type="button">Go</button>
                                </div>
                            </div>
                            <div className="category-block">
                                <div className="links-box">
                                    <a href="#" className="active">
                                        All
                                    </a>
                                    <a href="#">New</a>
                                    <a href="#">Latest</a>
                                    <a href="#">Popular</a>
                                    <a href="#">Most Read</a>
                                    <a href="#">Portfolio</a>
                                    <a href="#">Mutual Fund</a>
                                    <a href="#">Equity Fund</a>
                                    <a href="#">Debt Fund</a>
                                    <a href="#">Goal Planning</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* blogpage&& to check data or to wait for data, dynamic data */}
                        {blogPage &&
                            blogPage.map((row, index) => {
                                if (index === 0) {
                                    return (

                                        // <div className="col-lg-8">

                                        <NavLink to={`/blog/${row.slug}`} className="col-lg-8">
                                            <div className="blog-item">
                                                <img src={`${UPLOADNODEURL}${row.image}`} alt="" />
                                                <div className="content big">
                                                    <h3>

                                                        {/* <NavLink to={`/blog/${row._id}`}> */}
                                                        {Parser(row.title)}

                                                    </h3>
                                                    <span>By Wealthynivesh</span>
                                                    <p>
                                                        {moment(row.updatedtime).format(
                                                            'MMMM DD, YYYY'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>

                                        </NavLink>
                                        // </div>     
                                    );
                                }
                                return null;
                                // map function is expected to return hence it is used to satisfy the map*
                            })}

                        {blogPage && (
                            <div className="col-lg-4">
                                {blogPage.map((row, index) => {
                                    if (index === 1 || index === 2) {
                                        return (
                                            // <div className="blog-item">
                                            <NavLink to={`/blog/${row.slug}`} className="blog-item">
                                                <img src={`${UPLOADNODEURL}${row.image}`} alt="" />
                                                <div className="content">
                                                    <h3>
                                                        {/* <NavLink to={`/blog/${row._id}`}> */}
                                                        {Parser(row.title)}
                                                        {/* </NavLink> */}
                                                    </h3>
                                                    <span>By Wealthynivesh</span>
                                                    <p>
                                                        {moment(row.updatedtime).format(
                                                            'MMMM DD, YYYY'
                                                        )}
                                                    </p>
                                                </div>
                                                {/* </div> */}

                                            </NavLink>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        )}
                    </div>
                    <div className="row">
                        {blogPage &&
                            blogPage.map((row, index) => {
                                if (index > 3) {
                                    // when data is more than 3 it will show data
                                    return (
                                        <div className="col-lg-4">
                                            <div className="blog-item">
                                                <img src={`${UPLOADNODEURL}${row.image}`} alt="" />
                                                <div className="content">
                                                    <h3>
                                                        <NavLink to={`/blog/${row.slug || row._id}`}>
                                                            {Parser(row.title)}
                                                        </NavLink>
                                                    </h3>
                                                    <span>By Wealthynivesh</span>
                                                    <p>
                                                        {moment(row.updatedtime).format(
                                                            'MMMM DD, YYYY'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogBlock;
