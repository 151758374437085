import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import Parser from 'html-react-parser';
import { BASEAPIURL, UPLOADNODEURL } from '../../config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import partner1 from '../../assets/images/partners-img1.jpg';
// import partner2 from '../../assets/images/partners-img2.jpg';
// import partner3 from '../../assets/images/partners-img3.jpg';
// import partner4 from '../../assets/images/partners-img4.jpg';
// import partner5 from '../../assets/images/partners-img5.jpg';
// import partner6 from '../../assets/images/partners-img6.jpg';
// import partner7 from '../../assets/images/partners-img7.jpg';
// import partner8 from '../../assets/images/partners-img8.jpg';

function PartnersBlock({ className }) {
    const sliderRef = useRef();
    const teamslider = {
        autoplay: true,
        arrows: false,
        dots: false,
        slidesToShow: 6,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [Testimonial, setTestimonial] = useState([]);

    const FetchFaqs = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/ourpartners/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    RY: 'SJAIN',
                    recordsPerPage: 100,
                    sortby: 'a2z',
                    SortStatus: 1,
                }),
            });
            console.log(res);
            const resJson = await res.json();
            if (res.status === 200) {
                setTestimonial(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchFaqs();
    }, []);
    return (
        <>
            <section className={`appie-partners-area ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">Our Partners</h3>
                                {/* <p>We work with reputed brands across industries</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-20">
                        <div className="col-lg-12">
                            <div className="slider-item">
                                <Slider ref={sliderRef} {...teamslider}>
                                    {Testimonial.map((singi) => (
                                        <div>
                                            <div className="content">
                                                <img
                                                    src={`${UPLOADNODEURL}${singi.image}`}
                                                    alt=""
                                                />
                                                <p>{Parser(singi.title)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PartnersBlock;
