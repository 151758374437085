/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import Slider from "@mui/material/Slider";
import style from "./goal.module.css";
import GoalCalculationGraph from "./GoalChart";

const GoalComponent = (props) => {
  const { values, handleTimeRange, handleRoiRange,handleAmountChange, outputResult, graphData } = props;
  const [selectedType, setSelectedType] = useState({name:"Home"});
  const selectRef = useRef(null);

  const onButtonClick = (clickedType) => {
    // Your logic to handle the button click
    console.log('Button clicked in parent:', clickedType);
    // ... other logic ...
  };

  const handleButtonClick = (clickedType) => {
    onButtonClick(clickedType);
    setSelectedType(clickedType);
    if (selectRef.current) {
      selectRef.current.value = clickedType.name;
    }
  };

  const handlePurposeClick = (event, clickedType) => {
    event.preventDefault();
    onButtonClick(clickedType);
    setSelectedType(clickedType);

    if (selectRef.current) {
      selectRef.current.value = clickedType.name;
    }
  };

  const formateValue = (value) => new Intl.NumberFormat('en-IN', {
    currency: 'INR',
    maximumFractionDigits: 0
  }).format(value);
  // const formateValue = (value) =>{
  //   console.log("value", value)
  // }

  const purposeData = [
    { option:1, name: "home", src: "/images/icon/iconamoon_home-light.svg", label: "Home" },
    { option:2, name: "car_bike", src: "/images/icon/clarity_car-line.svg", label: "Car/Bike" },
    { option:3, name: "trip", src: "/images/icon/Vector.svg", label: "Foreign Trip" },
    { option:4, name: "marriage", src: "/images/icon/marriage_ring.svg", label: "Marriage" },
    { option:5, name: "education", src: "/images/icon/Vector.svg", label: "Education" },
    { option:6, name: "retirement", src: "/images/icon/Group.svg", label: "Retirement" },
    { option:7, name: "other", src: "/images/icon/18.svg", label: "Other Plan" }
  ];


  return (
    <div className={style.layout}>
      <div className={style.container_top}>
        {console.log("==>",selectedType.name)}
        {purposeData.map((icon) => (
          
          <div
            key={icon.option}
            className={selectedType.name === icon.name ? style.top_icon_selected : style.top_icon}
            onClick={(event) => handlePurposeClick(event, { name: icon.name })}
          >
            <img src={icon.src} alt={icon.label}/>
            <p>{icon.label}</p>
          </div>
        ))}
      </div>
      <div className={style.container_bottom}>
        <div className={style.bottom_left}>
          <div className={style.selection_input}>
            <div className={style.choose_goal}>
              <div className={style.choose_goal_text}>Choose your Goal</div>
              <select ref={selectRef} name="amount" id="amount" className="select" onChange={(e) => handleButtonClick({ name: e.target.value })}>
                <option value="" disabled selected>Select</option>
                {purposeData.map((icon) => (
                  <option key={icon.option} value={icon.name}>{icon.label}</option>
                ))}
              </select>
            </div>
            <div className={style.amount}>
              <div className={style.choose_goal_text}>Amount</div>
              <div className={style.symbol_input} >
                <span className={style.currency_symbol}>₹</span>
                <input type="number" value={values.investmentAmount} onChange={handleAmountChange}/>
              </div>
            </div>
            <div className={style.time}>
              <div className={style.choose_time_text}>Time</div>
              <div className={style.slider_component}>
                <Slider
                  value={values.time}
                  min={values.timeRange.min}
                  max={values.timeRange.max}
                  onChange={handleTimeRange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value} Year`}
                  sx={{
                    color: '#00EBB4',
                    height: '10px',
                    '& .MuiSlider-thumb': {
                      borderRadius: '100px',
                      color: "white",
                    },
                    '& .MuiSlider-.Mui-completed': {
                      color: "pink",
                    },
                  }}
                />
              </div>
              <div className={style.range_indicator}>
                <p>2 year</p>
                <p>30 year</p>
              </div>
            </div>
            <div className={style.roi}>
              <div className={style.choose_time_text}>ROI</div>
              <div className={style.slider_component}>
                <Slider
                  value={values.roi || 3}
                  min={values.roiRange.min}
                  max={values.roiRange.max}
                  onChange={handleRoiRange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value} %`}
                  sx={{
                    color: '#00EBB4',
                    height: '10px',
                    '& .MuiSlider-thumb': {
                      borderRadius: '100px',
                      color: "white",
                    },
                    '& .MuiSlider-.Mui-completed': {
                      color: "pink",
                    },
                  }}
                />
              </div>
              <div className={style.range_indicator}>
                <p>{`${values.roiRange.min}%`}</p>
                <p>{`${values.roiRange.max}%`}</p>
              </div>
            </div>
          </div>
          <div className={style.submit_button}>
            <div className={style.start_investment}>
              <Link to="#">
                <button type="submit">Start Investment</button>
              </Link>
            </div>
          </div>
        </div>
        <div className={style.bottom_right}>
          <div className={style.graph}>
            {/* <div className={style.graph_indicator}></div> */}
            <div className={style.graph_line_graph}>
              <GoalCalculationGraph graphData={graphData} />
            </div>
          </div>
          <div className={style.calculate_value}>
            <div className={style.goal_invested_value}>
              <p>Invested Amount </p>
              <p>₹ {formateValue(outputResult.investedAmount)}</p>
            </div>
            <div className={style.goal_estimated_returns}>
              <p>Estimate Returns</p>
              <p>₹ {formateValue(outputResult.estimateReturn)}</p>
              {/* {formateValue(outputResult.estimateReturn)} */}
            </div>
            <div className={style.goal_total_value}>
              <p>Total Value</p>
              <p>₹ {formateValue(outputResult.totalValue)}</p>
            </div>
          </div>
          <div className={style.result}>
            <h6>Result </h6>
            <div className={style.goal_sip_required}>
              <p>SIP Required</p>
              <p className={style.height_light}>₹ {formateValue(outputResult.requiredSIP)}</p>
            </div>
            <div className={style.goal_lumpsum_required}>
              <p>Lumpsum Required</p>
              <p className={style.height_light}>₹ {formateValue(outputResult.requiredLumpsum)}</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default GoalComponent;
