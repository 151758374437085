/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import GoalComponent from "./GoalComponent";
import GoalContent from "./GoalContent";
import style from "./goal.module.css";
import { GoalFAQ } from "../MutualFund/FAQ";
import FAQComponent from "../MutualFund/LumpsumFAQ";

const GoalCalculator = (props) => {
  const [goalValue, setGoalValue] = useState({
    goalType: "Home",
    investmentAmount: 10000,
    time: 5,
    timeRange: {
      min: 2,
      max: 30,
    },
    roi: 12,
    roiRange: {
      min: 5,
      max: 30,
    },
  });
  const [graphData, setGraphData] = useState({
    years: [1, 2, 3, 4, 5],
    investedGraph: [10000, 10000, 10000, 10000, 10000],
    gainGraph: [11200, 12544, 14049, 15735, 17623],
  });
  const [outputResult, setOutputResult] = useState({
    investedAmount: 50000,
    estimateReturn: 147596,
    totalValue: 247596,
    requiredSIP: 2000,
    requiredLumpsum: 40000,
  });

  useEffect(() => {
    calculateGoalValues();
    calculateGraphValue();
  }, [goalValue]);

  console.log("outputResult=>", outputResult)

  const handleTimeRange = (event, newValue) => {
    setGoalValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      time: newValue,
    }));
  };

  const calculateGoalValues = () => {
    const { investmentAmount, time, roi } = goalValue;

    const investedAmount = calculateInvestedAmount(
      investmentAmount,
      time,
      roi
    );
    const estimateReturn = calculateEstimateReturn(
      investmentAmount,
      time,
      roi
    );
    const requiredSIP = calculateSIP(
      investmentAmount,
      time,
      roi
    );
    const requiredLumpsum = calculateLumpsum(
      investmentAmount,
      time,
      roi
    );

    const totalValue = calculateTotalValue(investedAmount, estimateReturn);

    setOutputResult((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investedAmount: investedAmount,
      estimateReturn: estimateReturn,
      totalValue: totalValue,
      requiredSIP: requiredSIP,
      requiredLumpsum: requiredLumpsum
    }));
  };

  const generateYears = (value) => {
    const MIN_YEAR = 1;
    if (value < 1 || value > 30) {
      console.error("Value must be between 1 and 30");
      return [];
    }
    const currentYear = 1;
    const years = [1];
    console.log("value", value)
    for (let i = 1; i < value; i++) {
      if (value > years.length) {
        years.push(currentYear + i);
      } else if (value < years.length) {
        years.pop();
      }
    }
    return years;
  };

  const calculateGraphGain = (years, investment, newValue, roi) => {
    const gains = [];
    const yearlyRoi = roi / 100;
    for (let i = 0; i < years.length; i++) {
      const j = i + 1
      const futureValue = investment * (1 + yearlyRoi) ** j
      const gain = Math.floor(futureValue - investment);
      gains.push(gain);
    }
    return gains;
  };


  const calculateGraphInvestment = (years, investment, duration) => {
    const investments = [];
    for (let i = 0; i < years.length; i++) {
      const totalInvestment = investment * 1;
      investments.push(totalInvestment);
    }
    return investments;
  };

  const calculateGraphValue = () => {
    const { investmentAmount, roi, time } = goalValue;
    const years = generateYears(time);
    const graphGain = calculateGraphGain(years, investmentAmount, time, roi);
    const graphInvestment = calculateGraphInvestment(years, investmentAmount, time);
    setGraphData((prev) => ({
      ...prev,
      years: years,
      gainGraph: graphGain,
      investedGraph: graphInvestment,
    }));
  };

  const calculateInvestedAmount = (investmentAmount, time, roi) => {
    let frequency = "yearly";
    if (frequency !== "monthly") {
      return investmentAmount * time;
    }
    return investmentAmount * time * 12;
  };

  const calculateEstimateReturn = (investment, time, roi) => {
    const r = roi / 100;
    return Math.floor(investment * Math.pow(1 + r, time));
  };

  const calculateTotalValue = (investedAmount, estimateReturn) => {
    let value = Math.floor(investedAmount + estimateReturn);
    console.log("🚀 ~ file: GoalCalculator.js:175 ~ calculateTotalValue ~ value:", value)
    return value;
  }

  const calculateSIP = (investmentAmount, time, roi) => {
    let monthlyRoi = roi / 12 / 100; // Convert annual ROI to monthly
    let denominator = Math.pow((1 + monthlyRoi), 12 * time) - 1;
    let sipValue = investmentAmount / (((denominator / monthlyRoi) * (12 + monthlyRoi) / 12))

    return Math.floor(sipValue);
  };

  const calculateLumpsum = (investmentAmount, time, roi) => {
    let value = Math.floor(investmentAmount / Math.pow((1 + (roi / 100)), time));
    return value;
  };


  const handleRoiRange = (event, newValue) => {
    setGoalValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      roi: newValue,
    }));
  };

  const handleAmountChange = (event) => {
    let lastValue = 100000000;
    let inputValue = event.target.value.replace(/,/g, ''); // Remove commas
    setGoalValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investmentAmount: inputValue > lastValue ? lastValue : inputValue,
    }));
  };


  return (
    <div className={style.goal_calculator}>
      <p className={style.goal_calculator_heading}>Goal Calculator</p>
      <p>
        Use MyMoneyPanda Goal Calculator to plan your investments wisely.
      </p>
      <GoalComponent
        handleAmountChange={handleAmountChange}
        handleTimeRange={handleTimeRange}
        handleRoiRange={handleRoiRange}
        values={goalValue}
        outputResult={outputResult}
        graphData={graphData}
      />
      <GoalContent />
      <FAQComponent questions={GoalFAQ} />
    </div>
  );
};

export default GoalCalculator;
