import React from "react";  
import './css/footermutualfund.css'

function FooterMutualFund(){
    return <div className="fmf-main">
        <h2 className="fmf-title">
        I am Ready to Invest 
        </h2>
        <a href="https://invest.mymoneypanda.in/">
            <div className="fmf-button">
                <h2>Sign up now</h2>
            </div>
        </a>

    </div>
}

export default FooterMutualFund;
