import React from "react";
import { Helmet } from "react-helmet";
import styles from './css/Content.module.css';

const LumpsumContent = () => (
  <>
    <Helmet>
      <title>Lumpsum Calculator- Lumpsum Investment Plan | MyMoneyPanda</title>
      <meta
        name="description"
        content="Calculate your lumpsum investment's potential growth and value
        with our easy-to-use online MyMoneyPanda lumpsum calculator.Plan for retirement,
        investments, or other financial milestones confidently."
      />
      <meta
        name="keywords"
        content="Lumpsum Calculation, Lumsum Calculator Online"
      />
    </Helmet>
    <div className={styles.block1}>
      <h1 className={styles.heading}>Lumsum Calculator Online</h1>
      <div className={styles.sub_heading}>
        A lumpsum calculator is an online tool that allows investors to estimate the future value of a single, one-time investment over a specified period. By entering the initial investment amount, expected rate of return, and investment duration, the calculator provides a quick and easy way to assess the potential growth of your investment. It's a valuable resource for investors looking to understand the potential returns on a lumpsum investment.
      </div>
    </div>

    {/* simple */}
    <div className={styles.block1}>
      <h2 className={styles.heading}>What is Lumpsum Calculation?</h2>
      <div className={styles.sub_heading}>
        Lumpsum calculation is a financial concept used to determine the potential growth of a single, one-time investment over a specified period. Unlike periodic investments like SIPs, where you invest at regular intervals, a lumpsum investment involves investing a large sum of money all at once. The calculation takes into account factors such as the initial investment amount, the rate of return, and the time period to estimate the future value of the investment.
      </div>
    </div>

    <div className={styles.block1}>
      <h2 className={styles.heading}>
        MyMoneyPanda Lumpsum Calculator: How to use it?
      </h2>
      <div className={styles.sub_heading}>
        Total SIP amount: This is the total amount of money invested through the SIP. It is calculated by multiplying the SIP frequency (number of times per year the investment is made) by the SIP amount per installment and the number of years for which the SIP is invested.
        <br />

        <div className={styles.pre_heading}>
          <div className={styles.bold_content}>Total SIP Amount = f * P * y</div>
        </div>

        <div className={styles.pre_heading}>
          Where <br />
          f = SIP Frequency <br />
          P = SIP Amount per Installment <br />
          Y = Number of Years <br /><br />
        </div>

        <div className={styles.pre_heading}>
          For example, if you invest Rs. 10,000 per month for 36 months, your total SIP amount will be Rs. 3,60,000. <br /><br />
        </div>

        <div className={styles.pre_heading}>
          Market Value: This is the assumed market value of the investments at the end of SIP tenure. <br /><br />
        </div>

        <div className={styles.pre_heading}>
          <div className={styles.bold_content}>
            Market Value, M = P * {'{[1 + i] \u00B2 - 1} / i'} * (1 + i) <br /><br />
          </div>
        </div>

        <div className={styles.pre_heading}>
          Where <br />
          i  =  Assumed XIRR / Frequency (XIRR is the assumed annualized returns on SIPs) <br />
          n = No. of years (y) * Frequency <br /> <br />
        </div>

        <div className={styles.pre_heading}>
          For example, if you invest Rs. 10,000 per month for 36 months at an assumed XIRR of 12% per year, your accumulated market value will be Rs. 4,35,076.
        </div>

      </div>

    </div>

    {/* list --- Advantages */}
    <div className={styles.block1}>
      <h2 className={styles.heading}>
        Lumpsum Investment: Advantages
      </h2>
      <div className={styles.list}>
        <ul>
          <li>
            <b>Simplicity : </b>
            Lumpsum investment is straightforward, involving a single, one-time investment, making it easier to manage and track.
          </li>
          <li>
            <b>Immediate Market Exposure : </b> Investing a large amount all at once provides immediate exposure to the market, allowing you to capitalize on favorable market conditions.
          </li>
          <li>
            <b>Compounding Benefits : </b> The longer your money is invested, the more time it has to compound and grow. A lumpsum investment benefits from compounding right from the start.
          </li>
          <li>
            <b>Potential for Higher Returns : </b> If invested at the right time, a lumpsum investment can offer higher returns compared to regular investments, especially in a rising market.
          </li>
        </ul>
      </div>
    </div>

  </>
);
export default LumpsumContent;
