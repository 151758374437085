import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import Parser from 'html-react-parser';
import { BASEAPIURL, UPLOADNODEURL } from '../../config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import user1 from '../../assets/images/testimonial-img1.jpg';
// import user2 from '../../assets/images/testimonial-img2.jpg';

function TestimonialBlock({ className }) {
    const sliderRef = useRef();
    const teamslider = {
        autoplay: true,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [Testimonial, setTestimonial] = useState([]);

    const FetchTest = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/testimonials/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    RY: 'SJAIN',
                    recordsPerPage: 100,
                    sortby: 'a2z',
                    SortStatus: 1,
                }),
            });
            console.log(res);
            const resJson = await res.json();
            if (res.status === 200) {
                setTestimonial(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchTest();
    }, []);
    return (
        <>
            <section className={`appie-testimonial-area-two ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <span>Testimonials</span>
                                <h3 className="appie-title">Hear from our customers </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-20">
                        <div className="col-lg-12">
                            <div className="container">
                                <div className="slider-item">
                                    <Slider ref={sliderRef} {...teamslider}>
                                        {Testimonial.map((singi) => (
                                            <div key={singi.image}>
                                                <div className="content">
                                                    <img
                                                        src={`${UPLOADNODEURL}${singi.image}`}
                                                        alt=""
                                                    />
                                                    <div
                                                        className="custom_scrollBar"
                                                        style={{
                                                            height: '150px',
                                                            overflow: 'auto',
                                                        }}
                                                    >
                                                        <p>{Parser(singi.description)}</p>
                                                    </div>
                                                    <div className="userBox">
                                                        <div>
                                                            <ul>
                                                                <li>
                                                                    <i className="fas fa-star" />
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-star" />
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-star" />
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-star" />
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-star" />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="userBox">
                                                        <div>
                                                            <h3>{Parser(singi.title)}</h3>
                                                            <p>{Parser(singi.subtitle)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TestimonialBlock;
