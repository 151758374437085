import React, { useState,  useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import YouTube from 'react-youtube';

import ReactPlayer from'react-player';
import PopupVideo from '../PopupVideo';

function SliderBlock({ className }) {
    const [showVideo, setVideoValue] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };

    // const sliderRef = useRef();
    // const homeslider = {
    //     autoplay: true,
    //     arrows: false,
    //     dots: true,
    //     slidesToShow: 1,
    //     autoplaySpeed: 4000,
    //     pauseOnHover: false,
    // };
    useEffect(() => {
        localStorage.setItem('CanInvest', 1);
        localStorage.setItem('ShouldInvest', 1);
        localStorage.setItem('WhereInvest', 0);
    }, []);
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/7TWKKww-F30?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <h1 className="appie-title">
                                    Unlock your financial potential with My Money Panda
                                </h1>
                                <span>Do you know?</span>
                                <div className="invest-wrap">
                                    <div className="wrapBox">
                                        <b>How much can you invest?</b>
                                        <div className="checkBox">
                                            <span>No</span>
                                            <label className="switch" htmlFor="checkOne">
                                                <input
                                                    type="checkbox"
                                                    id="checkOne"
                                                    defaultChecked
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            localStorage.setItem('CanInvest', 1);
                                                        } else {
                                                            localStorage.setItem('CanInvest', 0);
                                                        }
                                                    }}
                                                />
                                                <div className="slider round"></div>
                                            </label>
                                            <span>Yes</span>
                                        </div>
                                    </div>
                                    <div className="wrapBox">
                                        <b>How much should you invest?</b>
                                        <div className="checkBox">
                                            <span>No</span>
                                            <label className="switch" htmlFor="checkTwo">
                                                <input
                                                    type="checkbox"
                                                    id="checkTwo"
                                                    defaultChecked
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            localStorage.setItem('ShouldInvest', 1);
                                                        } else {
                                                            localStorage.setItem('ShouldInvest', 0);
                                                        }
                                                    }}
                                                />
                                                <div className="slider round"></div>
                                            </label>
                                            <span>Yes</span>
                                        </div>
                                    </div>
                                    <div className="wrapBox">
                                        <b>Where should you invest?</b>
                                        <div className="checkBox">
                                            <span>No</span>
                                            <label className="switch" htmlFor="checkThree">
                                                <input
                                                    type="checkbox"
                                                    id="checkThree"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            localStorage.setItem('WhereInvest', 1);
                                                        } else {
                                                            localStorage.setItem('WhereInvest', 0);
                                                        }
                                                    }}
                                                />
                                                <div className="slider round"></div>
                                            </label>
                                            <span>Yes</span>
                                        </div>
                                    </div>
                                </div>
                                <p>Create your personalized money plan in less than 5 minutes</p>
                                <ul>
                                    <li>
                                        <NavLink to="/review?steps=investment-profile">
                                        {/* <NavLink to="/review-investment"> */}
                                            Build now
                                            <i className="far fa-arrow-right" />
                                        </NavLink>
                                    </li>
                                    <li className="d-none">
                                        <a
                                            onClick={(e) => handleShowVideo(e)}
                                            className="item-2"
                                            href="#"
                                        >
                                            How does it work? <i className="fas fa-play" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="    ">
                             <ReactPlayer
      url="https://www.youtube.com/watch?v=Ku4srcYslEI&ab_channel=MyMoneyPandabyWealthyNivesh"
    autoplay    
      loop
      muted
      playing
      controls

    />
 

    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SliderBlock;
