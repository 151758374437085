import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/HeaderUser';
import Footer from '../Helper/Footer';
import userImg1 from '../../assets/images/user-img1.jpg';
import { BASEAPIURL, BASEURL } from '../../config';

const Token = localStorage.getItem('token');
const jwttoken = localStorage.getItem('jwttoken');
const LSName = localStorage.getItem('fname');
const LSEmail = localStorage.getItem('email');

function Contactdetail() {
    // const [user, setUser] = useState([]);
    const [drawer, drawerAction] = useToggle(false);
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [message, setMessage] = useState('');
    const [newpassword, setNewPassword] = useState([]);
    const [confirmpassword, setConfirmPassword] = useState([]);
    const [password, setPassword] = useState([]);

    useEffect(() => {
        if (Token && Token !== 0 && Token !== '0') {
            console.log(Token);
        } else if (jwttoken && jwttoken !== 0 && jwttoken !== '0') {
            console.log(jwttoken);
        } else {
            window.location.href = `${BASEURL}login?action=login&message=Please Login to Continue`;
        }
        const fetchUser = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/user/getProfile`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        token: Token,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    // setUser(resJson.user);
                    // setOldPassword(resJson.oldpassword);
                    // setNewPassword(resJson.newpassword);
                    // setConfirmPassword(resJson.confirmpassword);
                    console.log(resJson.data);
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-success">${resJson.message}</div>`));
                } else {
                    console.log(resJson.message);
                    setMessage(Parser(`<div class="alert alert-danger">${resJson.message}</div>`));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchUser();
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        console.log(Token, password, newpassword, confirmpassword);
        const data = { token: Token, password, newpassword, confirmpassword };
        fetch(`https://services.mymoneypanda.in/api/user/createNewPassword`, {
            method: 'POST',
            headers: {
                // Accept: 'application/json',
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log(result);
            result.json().then((resp) => {
                setMessage(Parser(`<div class="alert alert-success">${resp.message}</div>`));
                console.log(resp);
            });
        });
    }

    // function handleCancel() {
    //     setTimeout(() => {
    //         window.location.href = `${BASEURL}user-panel`;
    //     }, 100);
    //     console.log(BASEURL);
    // }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />

            <section className="admin-user-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile-block">
                                <div className="leftBox">
                                    <img src={userImg1} alt="" />
                                    <h5>{LSName}</h5>
                                    {/* <h5>{user.fname}</h5> */}
                                    <a href="#">
                                        <i className="far fa-envelope" />
                                        {LSEmail}
                                    </a>
                                    <hr />
                                    <div className="navBox">
                                        <NavLink exact to="/profile">
                                            <i className="far fa-user" />
                                            Basic Details
                                        </NavLink>
                                        {/* <NavLink exact to="/ContactDetail">
                                            <i className="far fa-address-book" />
                                            &nbsp;Contact Details
                                        </NavLink>
                                        <NavLink exact to="/BankDetail">
                                            <i className="far fa-landmark"></i>
                                            Bank Details
                                        </NavLink>
                                        <NavLink exact to="/nominee">
                                            <i className="far fa-user-plus" />
                                            Nominee Details
                                        </NavLink> */}
                                        <NavLink exact to="/change-password">
                                            <i className="far fa-lock" />
                                            Change Password
                                        </NavLink>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                localStorage.removeItem('jwttoken');
                                                window.location.href = BASEURL;
                                            }}
                                        >
                                            <div className="iconBox">
                                                <i className="fal fa-sign-out" />
                                            </div>
                                            <div>Logout</div>
                                        </NavLink>
                                    </div>
                                </div>
                                <hr className="d-lg-none d-block mt-50 mb-50" />
                                <div className="rightBox">
                                    <h6>Primary Account*</h6>
                                    <form method="POST" onSubmit={handleSubmit}>
                                        <div className="form-area mb-20">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        <input
                                                            type="text"
                                                            required
                                                            name="oldPassword"
                                                            // value={user.password}
                                                            placeholder="Bank IFSC code"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Bank IFSC code*</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="Number"
                                                            name="confirmpassword"
                                                            required
                                                            // value={user.confirmpassword}
                                                            placeholder="Account Number"
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Bank Account Number*</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        {/* <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder=" Nominee Applicable % "
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                        /> */}
                                                        <select
                                                            onBlur={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                            required
                                                            style={{
                                                                height: '61px',
                                                                width: '100%',
                                                                margin: '4px',
                                                            }}
                                                        >
                                                            <option value="Savings-Account">
                                                                Savings-Account
                                                            </option>
                                                            <option value="Current-Account">
                                                                Current-Account
                                                            </option>
                                                        </select>
                                                        <label>Bank Account Type*</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <h6>Secondary Account</h6>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        <input
                                                            type="text"
                                                            name="oldPassword"
                                                            // value={user.password}
                                                            placeholder="Bank IFSC code"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Bank IFSC code*</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="Number"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Account Number"
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Bank Account Number</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        {/* <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder=" Nominee Applicable % "
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                        /> */}
                                                        <select
                                                            onBlur={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                            style={{
                                                                height: '61px',
                                                                width: '100%',
                                                                margin: '4px',
                                                            }}
                                                        >
                                                            <option value="Savings-Account">
                                                                Savings-Account
                                                            </option>
                                                            <option value="Current-Account">
                                                                Current-Account
                                                            </option>
                                                        </select>
                                                        <label>Bank Account Type</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        <input
                                                            type="text"
                                                            name="oldPassword"
                                                            // value={user.password}
                                                            placeholder="Bank IFSC code"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Bank IFSC code*</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div
                                                        className="form-box"
                                                        style={{
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            type="Number"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder="Account Number"
                                                            onChange={(e) => {
                                                                setNewPassword(e.target.value);
                                                            }}
                                                        />
                                                        <label>Bank Account Number</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-box">
                                                        {/* <input
                                                            type="text"
                                                            name="confirmpassword"
                                                            // value={user.confirmpassword}
                                                            placeholder=" Nominee Applicable % "
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                        /> */}
                                                        <select
                                                            onBlur={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }}
                                                            style={{
                                                                height: '61px',
                                                                width: '100%',
                                                                margin: '4px',
                                                            }}
                                                        >
                                                            <option value="Savings-Account">
                                                                Savings-Account
                                                            </option>
                                                            <option value="Current-Account">
                                                                Current-Account
                                                            </option>
                                                        </select>
                                                        <label>Bank Account Type</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ clear: 'both' }}>
                                                <div style={styleMSG}>{message}</div>
                                            </div>
                                            <div className="col-lg-12 mt-20 text-lg-left text-center">
                                                <input type="submit" name="submit" value="Save" />
                                                <input
                                                    type="submit"
                                                    name="submit"
                                                    value="cancel"
                                                    className="cancel"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
}

export default Contactdetail;
