import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/HeaderUser';
import Footer from '../Helper/Footer';
import ReviewInvest from '../ReviewInvest/index';

function Portfolio() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />

            <section className="admin-user-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ReviewInvest forPage="incomeandexpenses" isEmbed="1" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <BackToTop />
        </>
    );
}

export default Portfolio;
