import React, { useState, useEffect } from 'react';
import {
    perMonthSaveArr,
    ExpensesType,
    ExpensesSubType,
    // ReactInvstDown,
} from '../../administrator/pages/Dashboard/Investors/masterData';
// const ExpensesTypeSelected = [1, 2, 3, 4];

function PageTwo({
    onButtonClick,
    HandleForm,
    FormData,
    HandleArrayForm,
    ExpensesList,
    handleRemoveExpenses,
    RefreshExpensesList,
    handleEditExpenses,
    handleAddExpenses,
    Delete2ReviewRnvestment,
    isEmbed,
}) {
    const DeleteIndex = '_id';
    const [MoreQ, setMoreQ] = useState(0);
    // const [styleMSG, setstyleMSG] = useState({ color: '#cc0000', padding: '5px' });
    const [message, setMessage] = useState();
    const [StartValue, setStartValue] = useState(0);
    const [EndValue, setEndValue] = useState(0);

    const showMessage = ()=>{
        setMessage('Please Enter Montly Income to Evaluate Your Monthly expenses');
    }

    function CalculateMonthlyInvestibleSurplus(forValue, monthlyIncomeget) {
        let StartPer = 0;
        let EndPer = 0;
        if (monthlyIncomeget) {
            const monthlyIncomeHere = Number(monthlyIncomeget) / 1;
            switch (forValue) {
                case 1:
                case '1':
                    StartPer = 0;
                    EndPer = 15;
                    break;

                case 2:
                case '2':
                    StartPer = 15;
                    EndPer = 30;
                    break;

                case 3:
                case '3':
                    StartPer = 30;
                    EndPer = 45;
                    break;

                case 4:
                case '4':
                    StartPer = 45;
                    EndPer = 100;
                    break;

                default:
                    StartPer = 0;
                    EndPer = 0;
                    break;
            }
            if (StartPer === 0 && EndPer === 0) {
                setStartValue(0);
                setEndValue(0);
            } else {
                if (StartPer === 0) {
                    setStartValue(0);
                } else {
                    setStartValue(((monthlyIncomeHere * StartPer) / 100).toFixed(0));
                }
                setEndValue(((monthlyIncomeHere * EndPer) / 100).toFixed(0));
            }
        }
    }

    function totalExpenses() {
        let Total = 0;
        ExpensesList.map((s) => {
            if (s.amount !== '') {
                Total += Number(s.amount);
            }
            return '';
        });
        return Total;
    }

    useEffect(() => {
        // CalculateMonthlyInvestibleSurplus(1);
        CalculateMonthlyInvestibleSurplus(FormData.perMonthSave, FormData.monthlyIncome);
    }, [FormData]);

    return (
        <main className="form-area">
           <div style={{ display: MoreQ === 1 ? 'none' : '' }}>
                <h6>Monthly Income (₹)</h6>
                <div className="mb-20">
                    <input
                        type="number"
                        id="monthlyIncomeHere"
                        placeholder="Enter Monthly Income"
                        name="monthlyIncome"
                        value={FormData.monthlyIncome}
                        onChange={(e) => {
                            HandleForm(e);
                            CalculateMonthlyInvestibleSurplus(
                                FormData.perMonthSave,
                                e.target.value
                            );
                        }}
                    />
                </div>
                <h6 className="mt-20 mb-20">
                    What % of your monthly income are you able to save ?
                </h6>
                <div className="mb-30 mt-20 innerDiv">
                    {perMonthSaveArr.map((singi) => (
                        <p key={singi.id} className="RYCheckBoxCont">
                            <input
                                type="radio"
                                id={`ridown_${singi.id}`}
                                checked={Number(FormData.perMonthSave) === Number(singi.id)}
                                name="perMonthSave"
                                value={singi.id}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        HandleForm(e);
                                        CalculateMonthlyInvestibleSurplus(
                                            e.target.value,
                                            FormData.monthlyIncome
                                        );
                                    } else {
                                        CalculateMonthlyInvestibleSurplus(
                                            FormData.perMonthSave,
                                            FormData.monthlyIncome,
                                        );
                                    }
                                }}
                            />
                            <label htmlFor={`ridown_${singi.id}`}>&nbsp;</label>
                            {singi.value}
                        </p>
                    ))}
                </div>
                <div
                    className="innerDiv mb-3"
                    style={{
                        display: StartValue === 0 && EndValue === 0 ? 'none' : '',
                    }}
                >
                    <h6 className="mb-10">
                        Your Monthly Investible Surplus
                        <span style={{ color: '#3d52ed' }}>
                            &nbsp; ₹{StartValue} - ₹{EndValue}
                        </span>
                    </h6>
                    <p>Amount which you save and can invest on a monthly basis</p>
                </div>
                <div className="col-md-12" style={{ color: 'red' }} >
                    <div className="hello" style={{ textAlign: 'center' }}>{message}</div>
                </div>
                <div
                    className="mb-2 text-lg-left text-right"
                    style={{
                        display: Number(FormData.perMonthSave) === 5 ? '' : 'none',
                        float: 'right',
                    }}
                >
                    <input
                        type="button"
                        value="Back"
                        onClick={() => {
                            onButtonClick('pageone');
                            HandleArrayForm('ExpensesList', ExpensesList);
                        }}
                        className={Number(isEmbed) === 1 ? 'd-none' : 'main-btn skip'}
                    />
                    <input
                        type="submit"
                        value="Next"
                        onClick={() => { 
                            if (FormData.monthlyIncome && FormData.monthlyIncome !== '') {
                                setMoreQ(1);
                                
                                // alert('sfkl123h');
                            } else {
                                // alert('sfklh');
                                showMessage();
                                // console.log(AjaxMsg);
                            }
                        }}
                        className="main-btn"
                    />
                    {/* Continue here if you wish to analyse in detail */}
                </div>

                <div
                    className="text-lg-right text-center mb-20"
                    style={{
                        display: Number(FormData.perMonthSave) === 5 ? 'none' : '',
                    }}
                >
                    <input
                        type="button"
                        value="Back"
                        onClick={() => {
                            onButtonClick('pageone');
                            HandleArrayForm('ExpensesList', ExpensesList);
                        }}
                        className={Number(isEmbed) === 1 ? 'd-none' : 'main-btn skip'}
                        // className="main-btn skip"
                    />
                    <input
                        type="submit"
                        value="Next"
                        onClick={() => {
                     
                            console.log("going to page three",window.history)
                           
                            if(FormData.monthlyIncome && FormData.monthlyIncome !== ''){
                                window.history.pushState(null, '', `?steps=goal-planning1`);
                                onButtonClick('pagethree');
                                HandleArrayForm('ExpensesList', ExpensesList);
                            } else{
                                showMessage();
                                // window.history.pushState(null, '', `?steps=goal-planning`);
                            }
                        }}
                        className={Number(isEmbed) === 1 ? 'd-none' : 'main-btn'}
                    />
                </div>
            </div>
            <div style={{ display: MoreQ === 1 ? '' : 'none' }}>
                <h6>Monthly Income (₹)</h6>
                <div className="mb-40">
                    <input
                        type="number"
                        placeholder="Enter Monthly Income"
                        value={FormData.monthlyIncome}
                        onClick={() => {
                            setMoreQ(0);
                        }}
                    />
                </div>
                <hr className="mb-30" />
                <h6>Monthly Expenses (₹)</h6>
                <div className="mb-50">
                    {ExpensesList &&
                        ExpensesList.map((single, run) => (
                            <div className="" key={single.index}>
                                <div className="row mb-20 mx-0 align-items-center">
                                    <div className="col-lg-4 p-0 pr-lg-4">
                                        <div className="selectdiv">
                                            <select
                                                className="mb-lg-0"
                                                name="type"
                                                value={single.type}
                                                onChangeCapture={(e) => {
                                                    RefreshExpensesList();
                                                    handleEditExpenses(
                                                        run,
                                                        e.target.name,
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option value="0">Type</option>
                                                {ExpensesType.map((s) => (
                                                    <option value={s.id} key={s.id}>
                                                        {s.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 p-0  pr-lg-4">
                                        <div className="selectdiv">
                                            <select
                                                className="mb-lg-0"
                                                name="subType"
                                                value={single.subType}
                                                onChangeCapture={(e) => {
                                                    RefreshExpensesList();
                                                    handleEditExpenses(
                                                        run,
                                                        e.target.name,
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option value="0">Sub Type</option>
                                                {ExpensesSubType.map((s) => (
                                                    <option
                                                        value={s.id}
                                                        key={s.id}
                                                        style={{
                                                            display:
                                                                Number(ExpensesList[run].type) ===
                                                                Number(s.parent)
                                                                    ? ''
                                                                    : 'none',
                                                        }}
                                                    >
                                                        {s.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 p-0">
                                        <input
                                            type="number"
                                            name="amount"
                                            value={single.amount}
                                            placeholder="Enter Amount"
                                            onChange={(e) => {
                                                RefreshExpensesList();
                                                handleEditExpenses(
                                                    run,
                                                    e.target.name,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-1">
                                        <button
                                            type="button"
                                            className="btn text-danger"
                                            style={{
                                                height: '40px',
                                                display: single.index === 1 ? 'none' : '',
                                            }}
                                            onClick={() => {
                                                handleRemoveExpenses(single.index);
                                                if (single[DeleteIndex]) {
                                                    Delete2ReviewRnvestment(
                                                        'expenses',
                                                        single[DeleteIndex]
                                                    );
                                                }
                                            }}
                                        >
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}

                    <div className="text-right">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            onClick={() => {
                                handleAddExpenses(Number(ExpensesList.length) + 1);
                            }}
                        >
                            <i className="fa fa-plus"></i>
                            &nbsp;Expenses
                        </button>
                    </div>
                </div>
                {/* #{totalExpenses()} */}
                <div
                    className="innerDiv"
                    style={{
                        display: Number(totalExpenses()) === 0 ? 'none' : '',
                    }}
                >
                    <h6 className="mb-2">
                        Your Monthly Investible Surplus  
                        <span style={{ color: '#3d52ed' }}>
                            &nbsp;₹{Number(FormData.monthlyIncome) - totalExpenses()}
                        </span>
                    </h6>
                    <p>Amount which you save and can invest on a monthly basis</p>
                </div>
                <div
                    className={Number(isEmbed) === 1 ? 'd-none' : 'mt-10 text-lg-right text-center'}
                >
                    <input
                        type="button"
                        value="Back"
                        onClick={() => {
                            setMoreQ(0);
                            HandleArrayForm('ExpensesList', ExpensesList);
                        }}
                        className="main-btn skip"
                    />
                    <input
                        type="submit"
                        value="Next"
                        onClick={() => {
                           
                            // window.history.pushState(null, '', `?steps=goal-planning`);
                            onButtonClick('pagethree'); 
                            HandleArrayForm('ExpensesList', ExpensesList);
                        }}
                        className="main-btn"
                    />
                </div>
            </div>
        </main>
    );
}

export default PageTwo;
