import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/Logo.jpeg';
import StickyMenu from '../../lib/StickyMenu';
import userImg1 from '../../assets/images/user-img1.jpg';
import { BASEURL } from '../../config';

function HeaderUser({ action }) {
    useEffect(() => {
        StickyMenu();
    }, []);
    return (
        <header className="appie-header-area appie-sticky">
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-end">
                        <div className="col-lg-2 col-3 order-1 order-sm-1">
                            <div className="appie-logo-box" style={{ maxWidth: '80px' }}>
                                <NavLink to="/">
                                    <img src={logo} alt="" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-8 order-3 order-sm-2">
                            <div className="appie-header-main-menu">
                                <ul>
                                    <li>
                                        <NavLink exact to="/user-panel">
                                            Dashboard
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/portfolio">Portfolio</NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/explore"
                                            onClick={() => {
                                                localStorage.setItem('explore', 1);
                                            }}
                                        >
                                            Explore
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/goal">Goal</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/income-and-expenses">
                                            Income & Expenses
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact">Contact</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-9 order-2 order-sm-3">
                            <div className="appie-btn-box">
                                <div className="user-box">
                                    <div className="appie-header-main-menu">
                                        <ul>
                                            <li>
                                                <a className="notifyIcon">
                                                    <i className="far fa-bell" />
                                                </a>
                                                {/* <ul className="sub-menu">
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-dot-circle" />
                                                            </div>
                                                            <div>
                                                                Wealthy Nivesh
                                                                <span>
                                                                    Lorem Ipsum is simply dummy text
                                                                    of the printing and typesetting
                                                                    industry.
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-dot-circle" />
                                                            </div>
                                                            <div>
                                                                Wealthy Nivesh
                                                                <span>
                                                                    Lorem Ipsum is simply dummy text
                                                                    of the printing and typesetting
                                                                    industry.
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-dot-circle" />
                                                            </div>
                                                            <div>
                                                                Wealthy Nivesh
                                                                <span>
                                                                    Lorem Ipsum is simply dummy text
                                                                    of the printing and typesetting
                                                                    industry.
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-dot-circle" />
                                                            </div>
                                                            <div>
                                                                Wealthy Nivesh
                                                                <span>
                                                                    Lorem Ipsum is simply dummy text
                                                                    of the printing and typesetting
                                                                    industry.
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-dot-circle" />
                                                            </div>
                                                            <div>
                                                                Wealthy Nivesh
                                                                <span>
                                                                    Lorem Ipsum is simply dummy text
                                                                    of the printing and typesetting
                                                                    industry.
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="user-box">
                                    <div className="appie-header-main-menu">
                                        <ul>
                                            <li>
                                                <a style={{ cursor: 'pointer' }}>
                                                    <img src={userImg1} alt="" />
                                                    <i className="fal fa-angle-down" />
                                                </a>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <NavLink to="/profile">
                                                            <div className="iconBox">
                                                                <i className="fal fa-user-circle" />
                                                            </div>
                                                            <div>
                                                                Profile
                                                                <span>
                                                                    Edit your personal & accounts
                                                                    details
                                                                </span>
                                                            </div>
                                                            <i className="far fa-angle-right" />
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-clipboard-list-check" />
                                                            </div>
                                                            <div>
                                                                Watchlist
                                                                <span>
                                                                    See what you watch recently
                                                                </span>
                                                            </div>
                                                            <i className="far fa-angle-right" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="iconBox">
                                                                <i className="fal fa-analytics" />
                                                            </div>
                                                            <div>
                                                                Report
                                                                <span>
                                                                    Lorem ipsum dolor sit met
                                                                </span>
                                                            </div>
                                                            <i className="far fa-angle-right" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/faq">
                                                            <div className="iconBox">
                                                                <i className="fal fa-comments" />
                                                            </div>
                                                            <div>
                                                                Help & FAQ
                                                                <span>Need any help</span>
                                                            </div>
                                                            <i className="far fa-angle-right" />
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="/"
                                                            onClick={() => {
                                                                localStorage.removeItem('jwttoken');
                                                                window.location.href = BASEURL;
                                                            }}
                                                        >
                                                            <div className="iconBox">
                                                                <i className="fal fa-sign-out" />
                                                            </div>
                                                            <div>Logout</div>
                                                            <i className="far fa-angle-right" />
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderUser;
