import React, { useState } from "react";
import minus from "../../assets/images/mutualfundimg/Icon wrap.svg";
import plus from "../../assets/images/mutualfundimg/Icon wrap (1).svg";
import './css/sixherosection.css'

function SixHeroSection(){
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (id) => {
        setOpenIndex(openIndex === id ? null : id);
    };

    const questions = [
        {
            id: '1',
            question: "What is a mutual fund?",
            answer: "A mutual fund is an investment vehicle that pools money from many investors to purchase a diversified portfolio of stocks, bonds, or other securities."
        },
        {
            id: '2',
            question: "How do mutual funds work?",
            answer: "Mutual funds work by using pooled funds from multiple investors to buy various assets. Profits and losses are shared among investors proportional to their investment."
        },
        {
            id: '3',
            question: "What are the benefits of investing in mutual funds?",
            answer: "Mutual funds offer diversification, professional management, and liquidity, making them a convenient investment choice for both novice and seasoned investors."
        },
        {
            id: '4',
            question: "What are the different types of mutual funds?",
            answer: "There are several types of mutual funds, including equity funds, debt funds, hybrid funds, index funds, and sector funds, each serving different investment objectives."

        },
        {
            id: '5',
            question: "How much do I need to invest in a mutual fund?",
            answer: "The initial investment amount can vary greatly, from as low as a few hundred rupees to crores, depending on the specific mutual fund."
        },
        {
            id: '6',
            question: "Are mutual funds risky investments?",
            answer: "All investments carry some level of risk, but the risk level of mutual funds depends on the type of assets they invest in. Equity funds are generally riskier than debt funds."
        }, 
        {
            id: '7',
            question: "Can I redeem my mutual fund shares anytime?",
            answer: "Yes, you can typically redeem your mutual fund shares at any time, though some funds may charge a fee if you withdraw before a certain period."
        },
        {
            id: '8',
            question: "When is the best time to invest in mutual funds?",
            answer: `The best time to invest in mutual funds depends on your financial goals and market conditions. However, a common strategy is to invest regularly over time, a technique known as "rupee-cost averaging".`
        }
    ];

    return <>
        <div className="sihs-main">
         <h2 className="sihs-title">Frequently asked questions</h2>
         <div className="sihs-container">
            {questions.map((item) => (
               <div key={item.id} className="sihs-question" style={{paddingBottom : openIndex === item.id ? '2rem' : '1rem'}}>
                  <div className="sihs-question-header">
                     <p className="sihs-question-title">{item.question}</p>
                     <div onClick={() => handleClick(item.id)} className="sihs-question-icon">
                        <img src={openIndex === item.id ? minus : plus} alt={openIndex === item.id ? "minus" : "plus"}/>
                     </div>
                  </div>
                  {openIndex === item.id && (
                     <p className="sihs-answer">
                        {item.answer}
                     </p>
                  )}
               </div>
            ))}
         </div>
      </div>
    </>
}

export default SixHeroSection;
