import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';

import Home from './components/Home';
import Blog from './components/Blog';
import BlogSingle from './components/Blog/BlogSingle';
import AboutUs from './components/AboutUs';
import OurTeam from './components/OurTeam';
import Contact from './components/Contact';
import MutualFund from './components/MutualFund';
import Calculator from './components/Calculator';
import GoalCalculator from './components/Goal';
// import IncomeExpenses from './components/incomeAndExpenses/incomeAndExpenses';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ReviewInvest from './components/ReviewInvest';
import Information from './components/Information';
import Faq from './components/Faq';
// User Login

import UserPanel from './components/UserPanel';
import Portfolio from './components/UserPanel/Portfolio';
// import Explore from './components/UserPanel/Explore';
import incomeExp from './components/UserPanel/incomeExp';
import Goal from './components/UserPanel/Goal';
import Profile from './components/UserPanel/Profile';
import ChangePassword from './components/UserPanel/ChangePassword';

// administrator Login
import AdminLogin from './components/administrator/pages/Login';
import AdminDashboard from './components/administrator/pages/Dashboard';
import Nominee from './components/UserPanel/Nominee';
import ContactDetail from './components/UserPanel/ContactDetail';
import BankDetail from './components/UserPanel/BankDetail';
import Media from './components/Media';
import Completion from './components/Completion';
// administrator Login

function Routes() {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams){
            localStorage.setItem('utm_medium', queryParams?.get('utm_medium'));
            localStorage.setItem('utm_source', queryParams?.get('utm_source'));
            localStorage.setItem('utm_campaign', queryParams?.get('utm_campaign'));
        }
    })
    
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                {/* <Router basename="/wealthy-nivesh"> */}
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/blog" component={Blog} />
                            <Route exact path="/media" component={Media} />
                            <Route exact path="/blog/:id" component={BlogSingle} />
                            {/* <Route exact path="/blog-single" component={BlogSingle} /> */}
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/our-team" component={OurTeam} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/mutual-funds-investment" component={MutualFund} />
                            <Route exact path="/mutual-funds-investment/:type" component={Calculator} />
                            <Route exact path="/goal-calculator" component={GoalCalculator} />
                            {/* <Route
                                exact
                                path="/user-panel#incomeData"
                                component={IncomeExpensenses}
                            /> */}
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/sign-up" component={SignUp} />
                            <Route exact path="/review" component={ReviewInvest} />
                            <Route exact path="/completion" component={Completion} />
                            {/* <Route exact path="/review-investment" component={ReviewInvest} /> */}
                            <Route exact path="/information/:InfoTitle" component={Information} />
                            <Route exact path="/faq" component={Faq} />
                            <Route exact path="/user-panel" component={UserPanel} />
                            <Route exact path="/portfolio" component={Portfolio} />
                            <Route exact path="/explore" component={UserPanel} />
                            <Route exact path="/goal" component={Goal} />
                            <Route exact path="/income-and-expenses" component={incomeExp} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/change-password" component={ChangePassword} />
                            <Route exact path="/nominee" component={Nominee} />
                            <Route exact path="/contactdetail" component={ContactDetail} />
                            <Route exact path="/bankdetail" component={BankDetail} />
                            <Route exact path="/admin-login" component={AdminLogin} />
                            <Route exact path="/admin-dashboard" component={AdminDashboard} />
                            <Route
                                exact
                                path="/admin-dashboard-:PageName"
                                component={AdminDashboard}
                            />
                            <Route exact path="/error" component={Error} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
