/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Helper/Footer';
import Header from '../Helper/HeaderMain';
import Drawer from '../Mobile/Drawer';
import SIPCalculator from './SIPCalculator';
import LumpsumCalculator from './LumpsumCalculator';


export default function CalCulator() {
    const { type } = useParams();
    console.log("🚀 ~ file: index.js:17 ~ CalCulator ~ type:", type)
    const [drawer, drawerAction] = useToggle(false);
    const [selectedType, setSelectedType] = useState("SIP");
    const [changeBack, setChangeBack] = useState("SIP")

    useEffect(() => {
        // Update selectedType when the 'type' parameter changes
        setSelectedType(type === "sip-calculator-online"? "SIP":"Lumpsum");
        setChangeBack(type === "sip-calculator-online" ? "SIP" : "Lumpsum");
    }, [type]);
    const handleClick = (type) => {
        setSelectedType(type);
        setChangeBack(type)
        console.log(`Button clicked: ${type}`);
    };
    console.log("csadkljcakls", useParams);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <div className='calculator_component'>
                {selectedType === "SIP"
                    ? <SIPCalculator handleSPIClick={handleClick} changeBack={changeBack} />
                    : <LumpsumCalculator handleLumpsumClick={handleClick} changeBack={changeBack} />}
            </div>
            <Footer />
            <BackToTop />
        </>
    )
}