import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import AboutBlock from './AboutBlock';
// import VideoBlock from '../Home/VideoBlock';
import AboutBlockTwo from './AboutBlockTwo';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>  
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <AboutBlock />
            {/* <VideoBlock /> */}
            <AboutBlockTwo />
            <Footer />
            <BackToTop />
        </>
    );
}

export default AboutUs;
