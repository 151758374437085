import React from 'react';
import './ProgressBar.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

function MultiStepProgressBar({ page, onPageNumberClick }) {
    let stepPercentage = 0;
    if (page === 'pageone') {
        stepPercentage = 16;
    } else if (page === 'pagetwo') {
        stepPercentage = 49.5;
    } else if (page === 'pagethree') {
        stepPercentage = 82.5;
    } else if (page === 'pagefour') {
        stepPercentage = 100;
    } else if (page === 'pagefive') {
        stepPercentage = 100;
    } else if (page === 'pageuser') {
        stepPercentage = 100;
    } else {
        stepPercentage = 0;
    }

    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        onClick={() => onPageNumberClick('1')}
                    >
                        {index + 1}
                        <span>Investor Profile</span>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        onClick={() => onPageNumberClick('2')}
                    >
                        {index + 1}
                        <span>Income & Expenses</span>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        onClick={() => onPageNumberClick('3')}
                    >
                        {index + 1}
                        <span>Goal Planning</span>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        onClick={() => onPageNumberClick('4')}
                    >
                        {index + 1}
                        <span>Current Investments</span>
                    </div>
                )}
            </Step>
        </ProgressBar>
    );
}

export default MultiStepProgressBar;
