import React from 'react';
import { NavLink } from 'react-router-dom';

function Error() {
    return (
        <>
            <section className="login-area">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-6">
                            <div className="errorBox">
                                <h1>404</h1>
                                <h2>oops... page not found</h2>
                                <NavLink to="/" className="main-btn">
                                    Back To Home
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Error;
