import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
// import { , showdatetimein } from '../../../../config';
import { BASEAPIURL } from '../../config';

function Faq({ className }) {
    const [Blogs, setBlogs] = useState([]);

    const FetchFaqs = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/faqs/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    RY: 'SJAIN',
                    recordsPerPage: 100,
                    sortby: 'a2z',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchFaqs();
    }, []);

    return (
        <>
            <section className={`appie-about-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content pr0">
                                <h2 className="title text-center">
                                    FAQ's
                                    <span className="mb-5 d-block">Frequently asked Questions</span>
                                </h2>
                                <div className="message-box">
                                    <div className="InformationPage">
                                        {Blogs.map((singi, i) => (
                                            <div className="contentBox" key={singi.fagid}>
                                                <div className="numBox">
                                                    <span>{i + 1}</span>
                                                </div>
                                                <div className="textBox">
                                                    <h5>{singi.title}</h5>
                                                    {Parser(singi.description)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
