/* eslint-disable camelcase */

export const Lumpsum_FAQ = [
    {
        id: '1',
        question: "Why would I need to use a lumpsum calculator?",
        answer: "A lumpsum calculator helps you estimate the potential returns on a one-time investment over a specified period, taking into account factors like interest rate and time. It's useful for planning your finances and assessing the growth of your investment."
    },
    {
        id: '2',
        question: "How often should I use a lumpsum calculator?",
        answer: "You can use a lumpsum calculator whenever you're considering a one-time investment, or when you want to assess the potential growth of your existing investments over time."
    },
    {
        id: '3',
        question: "What are the benefits of using a lumpsum calculator?",
        answer: "Using a lumpsum calculator helps you understand the potential growth of your investment, enabling you to make informed financial decisions. It's a handy tool for planning your finances, setting realistic goals, and tracking the progress of your investments."

    },
    {
        id: '4',
        question: "How can I find a reliable lumpsum calculator online? (mention MMP)",
        answer: "You can find reliable lumpsum calculators on many financial websites and investment platforms. My Money Panda offers a trusted and user-friendly lumpsum calculator to help you assess the growth potential of your one-time investments."
    },
    
];

export const SIP_FAQ = [
    {
        id:"1",
        question:"What is a SIP calculator?",
        answer:"A SIP calculator is a tool that helps you determine the potential returns on your Systematic Investment Plan (SIP) based on factors such as investment amount, duration, and expected returns."
    },
    {
        id:"2",
        question:"Can I start SIP with small amounts?",
        answer:"Yes, you can start a SIP with as little as a few hundred rupees, making it an accessible investment option for everyone."
    },
    {
        id:"3",
        question:"What will be the returns if I invest 5000 for 10 years?",
        answer:"The returns on a 5000 SIP for 10 years depend on factors like the annual return rate and market conditions. Typically, at an average return of 12%, your investment could grow to approximately ₹11.61 lakh."
    },
    {
        id:"4",
        question:"Can a SIP calculator predict market fluctuations?",
        answer:"No, a SIP calculator cannot predict market fluctuations. It provides estimates based on historical data and expected returns, but actual market performance may vary."
    },
    {
        id:"5",
        question:"Can a SIP calculator help me set realistic financial goals?",
        answer:"Yes, a SIP calculator can help you set realistic financial goals by providing an estimate of the potential returns on your investment over a specified period."
    },
    {
        id:"6",
        question:"Is it possible to change my SIP amount?",
        answer:"Yes, you can usually change your SIP amount by contacting your mutual fund provider or through your investment platform. However, certain funds may have restrictions on changing the SIP amount."
    },
    
];
export const GoalFAQ = [
    {
        id:"1",
        question:"What types of financial goals can I calculate with this tool?",
        answer:"It is designed to be versatile to assist you in achieving a broad spectrum of financial aspirations, including Retirement Planning, Education Fund, Emergency Fund, Homeownership, Debt Payoff, Investment Goals, and other general savings."
    },
    {
        id:"2",
        question:"What are good financial goals?",
        answer:"It is designed to be versatile to assist you in achieving a broad spectrum of financial aspirations, including Retirement Planning, Education Fund, Emergency Fund, Homeownership, Debt Payoff, Investment Goals, and other general savings."
    },
    {
        id:"3",
        question:"Can a goal calculator help me pick the right investment plan?",
        answer:"Yes. Providing recommendations based on your preferences for financial goals and risk tolerance, the Mutual Fund Goal Calculator by My Money Panda can help you pick the right investment plan, giving you nuanced input on varied investment products."
    },
    {
        id:"4",
        question:"Are the results provided by financial goal calculators accurate?",
        answer:"Financial calculators rely on specific assumptions during calculations. They have constraints that are not universally applicable. They might not accommodate unforeseen costs or market fluctuations, making them unsuitable for every scenario."
    },
    {
        id:"5",
        question:"How can a financial goal calculator help me?",
        answer:"A financial goal calculator is a powerful tool provided by My Money Panda to assist you in various ways:",
        list: ["Wealth Building", "Investment Planning", "Goal Clarity", "Budgeting", "Risk Assessments", "Realistic Time-frames for Your Goals"]
    },
    
];

