import React from "react";
import { Helmet } from 'react-helmet';
import styles from "./css/Content.module.css";


const SIPContent = () => (
  <>
    <Helmet>
      <title>SIP Calculator - Calculate Returns | MyMoneyPanda</title>
      <meta
        name="description"
        content="Use MyMoneyPanda SIP Calculator to plan your investments wisely. Calculate the potential returns and growth of your Systematic Investment Plan (SIP) over time."
      />
      <meta
        name="keywords"
        content="SIP Calculator, sip return calculator, lumpsum sip calculator"
      />
    </Helmet>
    <div className={styles.block1}>
      <h1 className={styles.heading}>SIP Calculator</h1>
      <div className={styles.sub_heading}>
        Systematic Investment Plans (SIPs) are a popular investment option that allow investors to contribute a fixed amount of money at regular intervals (e.g., monthly, quarterly) into a mutual fund or other investment vehicle. SIPs provide a disciplined approach to investing, making it easier for individuals to save and invest over time. By spreading investments over a period, SIPs reduce the impact of market volatility and enable investors to benefit from the power of compounding.
      </div>
    </div>

    <div className={styles.block1}>
      <h2 className={styles.heading}>
        What is SIP Calculator- SIP Return Calculator
      </h2>
      <div className={styles.sub_heading}>
        An SIP Calculator, also known as an SIP Return Calculator, is an online tool that helps investors calculate the potential returns on their Systematic Investment Plan (SIP) investments. By inputting the monthly investment amount, expected rate of return, and investment duration, the calculator provides an estimate of the total investment value and returns at the end of the specified period.
      </div>
    </div>


    <div className={styles.block1}>
      <h2 className={styles.heading}>
        Different Types of SIPs
      </h2>
      <div className={styles.sub_heading}>
        There are various types of SIPs tailored to meet the diverse needs of investors. At MyMoneyPanda, we offer specialized SIP baskets designed to cater to different investment goals and risk profiles.
      </div>
      <div className={styles.list}>
        <ul>
          <li>
            <b>All-Cap Basket : </b>
            This is a multi-cap portfolio with a balanced exposure across large-cap, mid-cap, and small-cap. This portfolio is suitable for investors with an investment horizon of more than 3 years.
          </li>
          <li>
            <b>Mid/Small Cap : </b>
            This is a mid and small cap focussed portfolio and recommended to investors who already have high exposure in large caps. This portfolio is suitable for aggressive risk profile clients with an investment horizon of more than 5 years.
          </li>
          <li>
            <b>Balanced Basket : </b>
            This is a hybrid portfolio that consists of approximately 65% exposure in equity and 35% in fixed income instruments. The portfolio offers stable returns with lower volatility than equity funds. This portfolio is suitable for moderate risk profile clients with an investment horizon of more than 2 years.
          </li>
        </ul>
      </div>
      <div className={styles.sub_heading}>
        Each of our SIP baskets offers unique features and benefits, ensuring that you can find the perfect fit for your investment goals, time horizon, and risk appetite.
      </div>
    </div>

    <div className={styles.block1}>
      <h2 className={styles.heading}>
        How Does MyMoneyPanda SIP Calculator Work?
      </h2>
      <div className={styles.sub_heading}>
        Total SIP amount: This is the total amount of money invested through the SIP. It is calculated by multiplying the SIP frequency (number of times per year the investment is made) by the SIP amount per installment and the number of years for which the SIP is invested.
        <br />

        <div className={styles.pre_heading}>
          <div className={styles.bold_content}>Total SIP Amount = f * P * y</div>
        </div>

        <div className={styles.pre_heading}>
          Where <br />
          f = SIP Frequency <br />
          P = SIP Amount per Installment <br />
          Y = Number of Years <br /><br />
        </div>

        <div className={styles.pre_heading}>
          For example, if you invest Rs. 10,000 per month for 36 months, your total SIP amount will be Rs. 3,60,000. <br /><br />
        </div>

        <div className={styles.pre_heading}>
          Market Value: This is the assumed market value of the investments at the end of SIP tenure. <br /><br />
        </div>

        <div className={styles.pre_heading}>
          <div className={styles.bold_content}>
            Market Value, M = P * {'{[1 + i] \u00B2 - 1} / i'} * (1 + i) <br /><br />
          </div>
        </div>

        <div className={styles.pre_heading}>
          Where <br />
          i  =  Assumed XIRR / Frequency (XIRR is the assumed annualized returns on SIPs) <br />
          n = No. of years (y) * Frequency <br /> <br />
        </div>

        <div className={styles.pre_heading}>
          For example, if you invest Rs. 10,000 per month for 36 months at an assumed XIRR of 12% per year, your accumulated market value will be Rs. 4,35,076.
        </div>

      </div>

    </div>

    <div className={styles.block1}>
      <h2 className={styles.heading}>
        Advantages of MyMoneyPanda SIP Calculator
      </h2>
      <div className={styles.list}>
        <ul>
          <li>

            <b>Ease of Use : </b> Our SIP Calculator is user-friendly and intuitive, making it easy for anyone to estimate their SIP returns.
          </li>

          <li>
            <b>Accurate Projections : </b> Get precise estimates of your potential SIP returns, helping you set realistic financial goals.
          </li>

          <li>
            <b>Free Access : </b> Our SIP Calculator is available for free, offering you a valuable tool to plan and optimize your SIP investments.
          </li>
        </ul>
      </div>
      <div className={styles.sub_heading}>
        Each of our SIP baskets offers unique features and benefits, ensuring that you can find the perfect fit for your investment goals, time horizon, and risk appetite.
      </div>
    </div>

    {/* list last box --- Mutual Fund */}
    <div className={styles.block1}>
      <h2 className={styles.heading}>
        Mutual Fund Investment: Its Importance
      </h2>
      <div className={styles.list}>
        <ul>
          <li>
            <b> Diversification : </b>
            Mutual funds invest in a diverse range of assets, reducing risk by spreading investments across various securities.
          </li>

          <li>
            <b>Professional Management : </b>
            Mutual funds are managed by experienced fund managers who use their expertise to make informed investment decisions
          </li>

          <li>
            <b>Affordability : </b>
            With mutual funds, you can start investing with a small amount of money, making it accessible for all levels of investors.
          </li>

          <li>
            <b>Ease of Investment : </b>
            Investing in mutual funds is simple and convenient, with options like SIPs allowing for automated, regular contributions.
          </li>

          <li>
            <b> Tax Benefits : </b>
            Some mutual funds, like Equity Linked Savings Schemes (ELSS), offer tax deductions, helping you save on taxes while investing.
          </li>

          <li>
            <b> Compounding Effect : </b>
            Mutual funds can help you benefit from the power of compounding, as your returns are reinvested, leading to exponential growth over time.
          </li>
        </ul>
      </div>
    </div>


  </>
);
export default SIPContent;
