import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

// import blog1 from '../../assets/images/blog-img1.jpg';
// import blog2 from '../../assets/images/blog-img2.jpg';
// import blog3 from '../../assets/images/blog-img3.jpg';
import { BASEAPIURL,  UPLOADNODEURL } from '../../config';
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function BlogSingle() {
    const [drawer, drawerAction] = useToggle(false);
    const [blogDetails,setBlogDetails]=useState()
    const { id } = useParams();

    const fetchBlogDetails=async()=>{
        const res = await fetch(`${BASEAPIURL}/admin/blog/GetBlogDetail/${id}`, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
         
        });

        const resJson = await res.json();
        if (res.status === 200) {

            // resJson.product.description.replace(`\\"`,`"`)
            setBlogDetails(resJson);
        } else {
            console.log(resJson.message);
        }
    }
    useEffect(() => {
        fetchBlogDetails();
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />

        {
            blogDetails&&     <section className="blogpage-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="single-blog-area">
                            <h4 className="blog-title">

{blogDetails&& blogDetails.product.title}
                            {/* What Jhunjhunwala Taught Me */}
                            </h4>
                            <div className="blog-date">
                                Published on {new Date(blogDetails.product.createdAt).toDateString()} <span>By Wealthynivesh</span>
                            </div>
                            <div className="blog-thumb">
                                <img style={{width:"100%"}} src={UPLOADNODEURL+blogDetails.product.image} alt="" />
                            </div>
                            {blogDetails && (<div className="blog-content">

                    {/* <div>
                        
                        
                        
                        custom text
                        <ul  style={{display:"list-item"}}>
                            <li style={{display:"list-item"}}>asdf</li>
                            <li>asdf</li>
                        </ul>
                        
                        
                        
                        </div> */}















                                <div className='blogContentDiv'  dangerouslySetInnerHTML={ {__html:blogDetails.product.description}}>


                            
                                </div>
                             
                             {/* <div className="blog-share">
                                 <h5>Share:</h5>
                                 <a href="#">
                                     <i className="fab fa-facebook-f" />
                                 </a>
                                 <a href="#">
                                     <i className="fab fa-linkedin-in" />
                                 </a>
                                 <a href="#">
                                     <i className="fab fa-instagram" />
                                 </a>
                                 <a href="#">
                                     <i className="fab fa-twitter" />
                                 </a>
                             </div> */}
                         </div>) }
                          
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-sidebar">
                             {/*    <div className="input-box">
                                    <input type="text" placeholder="Search" />
                                    <button type="button">Go</button>
                                </div>
                            </div>
                            {/* <aside className="widget">
                                <h3>Categories</h3>
                                <ul>
                                    <li>
                                        <a href="#">New</a>
                                        <span>(24)</span>
                                    </li>
                                    <li>
                                        <a href="#">Latest</a>
                                        <span>(15)</span>
                                    </li>
                                    <li>
                                        <a href="#">Popular</a>
                                        <span>(8)</span>
                                    </li>
                                    <li>
                                        <a href="#">Most Read</a>
                                        <span>(13)</span>
                                    </li>
                                    <li>
                                        <a href="#">Portfolio</a>
                                        <span>(4)</span>
                                    </li>
                                    <li>
                                        <a href="#">Mutual Fund</a>
                                        <span>(16)</span>
                                    </li>
                                    <li>
                                        <a href="#">Equity Fund</a>
                                        <span>(12)</span>
                                    </li>
                                    <li>
                                        <a href="#">Debt Fund</a>
                                        <span>(12)</span>
                                    </li>
                                    <li>
                                        <a href="#">Goal Planning</a>
                                        <span>(12)</span>
                                    </li>
                                </ul>
                            </aside> 
                            <aside className="widget">
                                <h3>Popular Posts</h3>
                                <div className="popular-posts">
                                    <img src={blog2} alt="" />
                                    <h5>
                                        <a href="#">Life with Financial Planning</a>
                                        <span>01 March 2023</span>
                                    </h5>
                                </div>
                                <div className="popular-posts">
                                    <img src={blog3} alt="" />
                                    <h5>
                                        <a href="#">Compounding - A Tool</a>
                                        <span>02 March 2023</span>
                                    </h5>
                                </div>
                                <div className="popular-posts">
                                    <img src={blog2} alt="" />
                                    <h5>
                                        <a href="#">Life with Financial Planning</a>
                                        <span>03 March 2023</span>
                                    </h5>
                                </div>
                                <div className="popular-posts">
                                    <img src={blog3} alt="" />
                                    <h5>
                                        <a href="#">Compounding - A Tool</a>
                                        <span>04 March 2023</span>
                                    </h5>
                                </div>
                                <div className="popular-posts">
                                    <img src={blog1} alt="" />
                                    <h5>
                                        <a href="#">What Jhunjhunwala Taught Me</a>
                                        <span>05 March 2023</span>
                                    </h5>
                                </div>
                            </aside>
                            <aside className="widget">
                                <h3>Popular Tags</h3>
                                <div className="popular-tags">
                                    <a href="#">New</a>
                                    <a href="#">Latest</a>
                                    <a href="#">Popular</a>
                                    <a href="#">Most Read</a>
                                    <a href="#">Portfolio</a>
                                    <a href="#">Mutual Fund</a>
                                    <a href="#">Equity Fund</a>
                                    <a href="#">Debt Fund</a>
                                    <a href="#">Goal Planning</a>
                                </div>
                            </aside> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }

            <Footer />
            <BackToTop />
        </>
    );
}

export default BlogSingle;
