import React, { useEffect, useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import Dashboard from './Dashboard';
import DownloadBlock from '../Home/DownloadBlock';
import RegisteredBlock from '../Home/RegisteredBlock';
import { BASEAPIURL, BASEURL } from '../../config';

let token = localStorage.getItem('token');
let jwttoken = localStorage.getItem('jwttoken');

function UserPanel() {
    const [drawer, drawerAction] = useToggle(false);
    const [RewiInvsts, setRewiInvsts] = useState({ goles: [] });

    const fetchUserRewiInvsts = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/users/reviewInvestment/fetch4user`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    token,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setRewiInvsts(resJson.UserData);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        token = localStorage.getItem('token');
        jwttoken = localStorage.getItem('jwttoken');
        if (token && token !== 0 && token !== '0') {
            console.log(token);
        } else if (jwttoken && jwttoken !== 0 && jwttoken !== '0') {
            console.log(jwttoken);
        } else {
            window.location.href = `${BASEURL}login?action=login&message=Please Login to Continue`;
        }
        fetchUserRewiInvsts();
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <Dashboard RewiInvsts={RewiInvsts} />
            <DownloadBlock className="pt-0" />
            <RegisteredBlock />
            <Footer />
            <BackToTop />
        </>
    );
}

export default UserPanel;
