import React from 'react';
import { NavLink } from 'react-router-dom';

function Navigation() {
    // const [open, setOpen] = useState(false);
    // const [cssClass, setClass] = useState("mobile_dropdown");


    function navigateBlogs() {
        window.location.href = "/blogs"
    }
    // const handleClick = (e)=>{
    //     e.preventDefault();
    //     window.location.href = "/"
    // }


    return (
        <>
            <ul>
                <li>
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                </li>
                <li className="dropdown" >
                    <NavLink to="/mutual-funds-investment">Mutual Fund</NavLink>
                    <div className="dropdown-content">
                        <NavLink to="/mutual-funds-investment/sip-calculator-online">Calculators</NavLink>
                        <div className="sub-dropdown">
                            <NavLink to="/mutual-funds-investment/sip-calculator-online">SIP Calculator</NavLink>
                            <NavLink to="/mutual-funds-investment/lumpsum-calculator-online">Lumpsum Calculator</NavLink>
                            <NavLink to="/goal-calculator">Goal Calculator</NavLink>
                            {/* <NavLink to="/mutual-funds-investment/cagr-calculator">CAGR Calculator</NavLink> */}
                        </div>
                    </div>
                </li>

                <li>
                    {/* <NavLink to="/blog">Blog</NavLink> */}
                    <a style={{ cursor: 'pointer' }} onClick={navigateBlogs} >Blog</a>
                </li>
                <li>
                    <NavLink to="/media">Media</NavLink>
                </li>
                <li>
                    <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li>
                    <NavLink to="/our-team">Our Team</NavLink>
                </li>
                <li>
                    <NavLink to="/contact">Contact</NavLink>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
