import React from 'react';
import regiImg1 from '../../assets/images/registered-img1.png';
import regiImg2 from '../../assets/images/registered-img2.png';
import regiImg3 from '../../assets/images/registered-img3.png';

function RegisteredBlock({ className }) {
    return (
        <>
            <section className={`appie-service-area ${className || ''}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Certifications</h3>
                                {/* <p>Works with dozens of top companies</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="appie-single-service text-center mt-30">
                                <div className="imgBox">
                                    <img src={regiImg1} alt="" />
                                </div>
                                <h5>AMFI Registered Mutual Fund Distributor ARN: 260681</h5>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-single-service text-center mt-30">
                                <div className="imgBox">
                                    <img src={regiImg2} alt="" />
                                </div>
                                <h5>100% Secure Payment Gateway BSE member code: 18823</h5>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-single-service text-center mt-30">
                                <div className="imgBox">
                                    <img src={regiImg3} alt="" />
                                </div>
                                <h5>SSL Enabled 100% Secure Platform</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RegisteredBlock;
