/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import CalculatorComponent from "./Calculator";
import LumpsumContent from "./LumpsumContent";
import style from "./css/calculator.module.css";
import {Lumpsum_FAQ} from "../MutualFund/FAQ";
import FAQComponent from "../MutualFund/LumpsumFAQ";

const LumpsumCalculator = (props) => {
  const [lumpsumValue, setLumpsumValue] = useState({
    investment: 100000,
    investmentRange: {
      min: 1000,
      max: 10000000,
    },
    investmentDuration: 8,
    frequency: "once",
    durationRange: {
      min: 2,
      max: 30,
    },
    roi: 12,
    roiRange: {
      min: 3,
      max: 30,
    },
  });
  const [graphData, setGraphData] = useState({
    years: [2024, 2025, 2026, 2027],
    investedGraph: [100000, 100000, 100000, 100000],
    gainGraph: [25440, 57352, 97382, 147596],
  });
  const [outputResult, setOutputResult] = useState({
    investedAmount: 100000,
    estimateReturn: 147596,
    totalValue: 247596,
  });

  useEffect(() => {
    calculateLumpsumValues();
    calculateGraphValue(lumpsumValue.investmentDuration);
  }, [lumpsumValue]);

  const { handleLumpsumClick, changeBack } = props;

  const calculateLumpsumValues = () => {
    const { investment, investmentDuration, roi, frequency } = lumpsumValue;

    const investedAmount = calculateInvestedAmount(
      investment,
      investmentDuration,
      frequency
    );
    const estimateReturn = calculateEstimateReturn(
      investment,
      investmentDuration,
      roi
    );

    const totalValue = calculateTotalValue(investedAmount, estimateReturn);

    setOutputResult((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investedAmount: investedAmount,
      estimateReturn: estimateReturn,
      totalValue: totalValue,
    }));
  };

  const generateYears = (value) => {
    const MIN_YEAR = 2000;
    if (value < 2 || value > 30) {
      console.error("Value must be between 2 and 30");
      return [];
    }
    const currentYear = new Date().getFullYear() % 100;
    const years = [MIN_YEAR + currentYear];
    for (let i = 1; i < value; i++) {
      if (value > years.length) {
        years.push(years[years.length - 1] + 1);
      } else if (value < years.length) {
        years.pop();
      }
    }
    return years;
  };

  const calculateGraphGain = (years, investment, newValue, roi) => {
    const gains = [];
    const yearlyRoi = roi / 100;
    // For For Lumpsum -FV = PV * (1 + r)^n
    // r = 12%, n = 3 years (total tenure of payment)
  
    for (let i = 0; i < years.length; i++) {
      const j = i+1
      const futureValue = investment * (1+yearlyRoi)**j
      // console.log("🚀 ~ file: LumpsumCalculator.js:101 ~ calculateGraphGain ~ futureValue:", futureValue)
      const gain = Math.floor(futureValue - investment);
      // console.log("🚀 ~ file: LumpsumCalculator.js:103 ~ calculateGraphGain ~ gain:", gain)
      gains.push(gain);
    }
  
    return gains;
  };
  

  const calculateGraphInvestment = (years, investment, duration) => {
    const investments = [];

    for (let i = 0; i < years.length; i++) {
      const totalInvestment = investment * 1;
      investments.push(totalInvestment);
    }

    return investments;
  };

  const calculateGraphValue = (newValue) => {
    const { investment, roi } = lumpsumValue;
    const years = generateYears(newValue);
    const graphGain = calculateGraphGain(years, investment, newValue, roi);
    const graphInvestment = calculateGraphInvestment(years, investment, newValue);

    setGraphData((prev) => ({
      ...prev,
      years: years,
      gainGraph: graphGain,
      investedGraph: graphInvestment,
    }));
  };

  const calculateInvestedAmount = (investment, duration, frequency) => {
    if (frequency !== "monthly") {
      return investment;
    }
    return investment * duration * 12;
  };

  const calculateEstimateReturn = (investment, investmentDuration, roi) => {
    const r = roi / 100;
    // FV = PV * (1 + r)^n
    return investment * Math.pow(1 + r, investmentDuration)-investment;
  };

  const calculateTotalValue = (investedAmount, estimateReturn) =>
    parseInt(investedAmount, 10) + parseInt(estimateReturn, 10);

  const handleRoiRange = (event, newValue) => {
    setLumpsumValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      roi: newValue,
    }));
  };

  const handleInvestmentEdit = (event, newValue) => {
    let lastValue = 1000000;
    let inputValue = event.target.value;
    setLumpsumValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investment: inputValue > lastValue ? lastValue : inputValue,
    }));
  };

  const handleInvestmentRange = (event, newValue) => {
    setLumpsumValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investment: newValue,
    }));
  };

  const handleDurationEdit = (event, newValue) => {
    let maximumYear = 30;
    let inputValue = event.target.value;
    setLumpsumValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investmentDuration: inputValue > maximumYear ? maximumYear : inputValue,
    }));
  };

  const handleDurationRange = (event, newValue) => {
    setLumpsumValue((prevLumpsumValue) => ({
      ...prevLumpsumValue,
      investmentDuration: newValue,
    }));
    calculateGraphValue(newValue);
  };

  return (
    <div className={style.SIP_calculator}>
      <p className={style.sip_calculator_heading}>Lumpsum Calculator</p>
      <p>
      Calculate your lumpsum investment's potential growth and value
with our easy-to-use online MyMoneyPanda lumpsum calculator.Plan for retirement,
investments, or other financial milestones confidently.
      </p>
      <CalculatorComponent
        onButtonClick={handleLumpsumClick}
        values={lumpsumValue}
        handleRoiRange={handleRoiRange}
        handleInvestmentEdit={handleInvestmentEdit}
        handleInvestmentRange={handleInvestmentRange}
        handleDurationEdit={handleDurationEdit}
        handleDurationRange={handleDurationRange}
        changeBack={changeBack}
        outputResult={outputResult}
        graphData={graphData}
      />
      <LumpsumContent />
      <FAQComponent questions={Lumpsum_FAQ}/>
    </div>
  );
};

export default LumpsumCalculator;
