// import { useState } from 'react';
import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Helper/Footer';
import Header from '../Helper/HeaderMain';
import Drawer from '../Mobile/Drawer';


export default function Media() {
    const [drawer, drawerAction] = useToggle(false);
    // const [videos,setVideos]=useState({
    //         id:"95EI7vxuZ7g",
    //         title:"Building Wealth & Financial Freedom with Boring Investments | Vaibhav Shah for My Money Panda",
    //         // by:"",
    //         date:"24th July 2023"
    // })

    return <>
        <Helmet>
            <title>Media: Latest Financial News & Tips - My Money Panda</title>
            <meta
                name="description"
                content="Explore My Money Panda's Media Hub for the latest in financial news, expert tips, and insightful resources to manage and grow your finances effectively."
            />
            <meta
                name="keywords"
                content="My Moneypanda"
            />
        </Helmet>
        <Drawer drawer={drawer} action={drawerAction.toggle} />
        <Header drawer={drawer} action={drawerAction.toggle} />
        <div>
            <section className='blogpage-section'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Media</h3>
                            </div>
                        </div>
                    </div>


                    <div className='mediaYoutube' style={{ display: "flex" }}>

                        <div className="" style={{ padding: "1rem" }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/gq_g4vPK5Uk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        Investing During Market Peaks: Unveiling the Benefits & Risks | Financial Planning | Mutual Funds.

                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Jul 20, 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ padding: "1rem" }} >
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/fffWaUwGw2s" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                                <div className="content big">
                                    <h3>
                                        Equity investment advice for entrepreneurs | Vaibhav Shah for My Money Panda


                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Jul 20, 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mediaYoutube' style={{ display: "flex", justifyContent: "center" }}>

                        <div className="mediaStyle" style={{ padding: "1rem", width: '50%' }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/95EI7vxuZ7g" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        Building Wealth & Financial Freedom with Boring Investments | Vaibhav Shah for My Money Panda

                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Jul 23, 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mediaStyle" style={{ padding: "1rem", width: '50%' }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/Nqw3ThiqkKA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        Can Multicap strategy generate superior returns in long term | Sanjay Chawla for My Money Panda


                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Aug 02, 2023
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mediaYoutube' style={{ display: "flex", justifyContent: "center" }}>

                        <div className="mediaStyle" style={{ padding: "1rem", width: '50%' }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/ZyZ7LfdRCIs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        40 Years Story of Indian Market Returns | MyMoneyPanda | Wealthy Nivesh


                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Jul 27, 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mediaStyle" style={{ padding: "1rem", width: '50%' }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/Gqblci1vieI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        Investment Summit Wealthy Nivesh Post Lunch


                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Jul 27, 2023
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mediaYoutube' style={{ display: "flex", justifyContent: "center" }}>


                        <div className="mediaStyle" style={{ padding: "1rem", width: '50%' }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/V31_HTJ4RaM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        Why choose stable investments over frequent trading | Vaibhav Shah for My Money Panda



                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Jul 27, 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mediaStyle" style={{ padding: "1rem", width: '50%' }}>
                            <div className="blog-item">
                                <iframe style={{ width: '100%' }} height="315" src="https://www.youtube.com/embed/XQ19Pchagrg" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>

                                <div className="content big">
                                    <h3>
                                        Decoding Debt Fund Investment Horizon | Mrs Anju Chajjar for My Money Panda



                                    </h3>
                                    <span>My Money Panda</span>
                                    <p>
                                        Aug 10, 2023
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>




        </div>


        {/* <BlogBlock />
            <DownloadBlock className="pt-0" />
            <RegisteredBlock /> */}
        <Footer />
        <BackToTop />
    </>
}